import { Fragment, useState } from 'react'
import type { ReactNode } from 'react'
import { Dialog as HeadlessDialog, Transition } from '@headlessui/react'

type Props = {
  title?: string
  children?: ReactNode
}

// SEE: https://headlessui.com/react/dialog
export function Dialog ({ title, children }: Props) {
  const [isOpen, setIsOpen] = useState(true)

  function closeModal () {
    setIsOpen(false)
  }

  return (
      <Transition appear show={isOpen} as={Fragment}>
        <HeadlessDialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 pb-20 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div>
                  <div className="flex justify-end">
                    <button className="text-white text-xl" tabIndex={-1} onClick={closeModal}>
                      <i className="far fa-times" />
                    </button>
                  </div>
                  <HeadlessDialog.Panel className="mt-1 w-full max-w-md transform max-h-md rounded-2xl bg-white px-3 py-6 text-left align-middle shadow-xl transition-all">
                    { title && <Title title={title} /> }
                    <div className="mt-3 overflow-scroll px-3" style={{ maxHeight: '60vh' }}>
                      { children }
                    </div>
                  </HeadlessDialog.Panel>
                </div>
              </Transition.Child>
            </div>
          </div>
        </HeadlessDialog>
      </Transition>
  )
}

function Title ({ title }: { title: string }) {
  return (
    <HeadlessDialog.Title
      as="h3"
      className="text-sm font-medium leading-6 text-gray-800 text-center"
    >
      { title }
    </HeadlessDialog.Title>
  )
}
