import { Turbo } from '@hotwired/turbo-rails'

function hasHistory (): boolean {
  return window.history.length > 1
}

function isHistoryBack (el: HTMLElement): boolean {
  return el.getAttribute('href') === 'javascript:history.back()'
}

function fallbackPath (el: HTMLElement): string {
  return el.dataset.backLinkFallbackPath ?? '/'
}

export function handleClick (event: MouseEvent) {
  const el = event.currentTarget as HTMLElement | null
  if (!el) { return }

  if (isHistoryBack(el) && !hasHistory()) {
    event.preventDefault()
    Turbo.visit(fallbackPath(el))
  } else {
    // fall through
  }
}
