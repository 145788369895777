import { AndroidBridge } from './mobile_bridges/android'
import { IOSBridge } from './mobile_bridges/ios'
import { EumoBridge } from './mobile_bridges/eumo'
import { isAndroid } from 'libs/user_agent'

export type MobileBridgeImpl = {
  scanQrCode(): void;
  scanQrCodeCardTransfer(): boolean;
  playSound(): void;
  sendText(text: string): void;
  download(url: string, filename: string): void;
  startGetCurrentLocation(): boolean;
}

class MobileBridge {
  readonly native: MobileBridgeImpl

  constructor (native: MobileBridgeImpl) {
    this.native = native
  }

  install (): void {
    (window as any).EUMO = new EumoBridge(this.native)
  }
}

const impl = isAndroid() ? new AndroidBridge() : new IOSBridge()
export const mobileBridge = new MobileBridge(impl)
