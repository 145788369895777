import { useRef, useEffect } from 'react'
import { client } from 'libs/ajax'

type Props = {
  nextPath: string
}

function observeIntersection (el: HTMLElement, onIntersection: Function): IntersectionObserver {
  let callbackCalled = false
  function callback (entries: IntersectionObserverEntry[]) {
    const [entry] = entries
    if (entry.isIntersecting && !callbackCalled) {
      callbackCalled = true
      onIntersection()
    }
  }

  const observer = new IntersectionObserver(callback)
  observer.observe(el)
  return observer
}

function fetchAndReplace (el: HTMLElement, nextPath: string): Function {
  return async () => {
    const { data } = await client.get<string>(nextPath)
    const targetEl = el.closest('x-load-next')
    if (targetEl) { targetEl.outerHTML = data }
  }
}

export function LoadNext ({ nextPath }: Props) {
  const loading = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (!loading.current) { return }

    const observer = observeIntersection(loading.current, fetchAndReplace(loading.current, nextPath))
    return () => { observer.disconnect() }
  }, [loading, nextPath])

  return (
    <div className="text-sm text-gray-400 text-center pt-5" ref={loading}>
      <i className="far fa-spinner fa-spin" />
      <span className="ml-2">
        読み込み中
      </span>
    </div>
  )
}
