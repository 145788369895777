import { useRef, useEffect, useState, ReactNode, ComponentProps, TouchEvent } from 'react'
import { StoreSearchForm } from './store_search_form'
import { newMap, Marker } from 'libs/map'
import { notice } from 'libs/flash'
import { updateAnchorQueryParams } from 'libs/dom'
import { SwipeDetector } from 'libs/swipe'

import * as storeApi from 'libs/http/store'
import { lastMapView, saveMapView, LatLng, MapView } from 'libs/location'

type StoreSearchFormProps = ComponentProps<typeof StoreSearchForm>

type Props = {
  apiKey: string;
  fallbackLocation: LatLng;
  pinnedStoreId: number | null;
  initialSearchParams: StoreSearchFormProps['initialValues'] | null;
} & Pick<StoreSearchFormProps, 'categories' | 'communities'>

type Map = Awaited<ReturnType<typeof newMap>>

function mapOptions (view: MapView): google.maps.MapOptions {
  return {
    center: { lat: view.latitude, lng: view.longitude },
    zoom: view.zoom || 15,
    zoomControl: true,
    disableDefaultUI: true,
    keyboardShortcuts: false,
    gestureHandling: 'greedy'
  } as google.maps.MapOptions
}

function storeMarker (store: storeApi.Store): Marker {
  return new Marker(store.id, store.latitude, store.longitude, store.name, {
    url: `/apps/stores/${store.id}/mapinfo`,
    icon: {
      url: store.marker_icon_url,
      scaledSize: Marker.iconSize(30, 40.5)
    }
  })
}

async function markStores (map: Map, params: storeApi.SearchParams): Promise<Marker[]> {
  // NOTE: 表示領域外の店舗も検索できるよう bounds は検索条件に含めていない
  // 一方で、一気にすべての店舗を地図上にプロットすると画面が重くなるので、表示領域内のものだけをプロットするよう renderMarkers で制御している
  const res = await storeApi.mapMarkers({ ...params, realStoreExists: true })
  const stores = res.filter(s => s.latitude && s.longitude)
  const markers = stores.map(s => storeMarker(s))
  map.setMarkers(markers)
  return markers
}

async function fetchActivities (map: Map, params: storeApi.SearchParams): Promise<string> {
  return await storeApi.mapActivities({ ...params, bounds: map.bounds })
}

function saveCurrentMapView (map: Map) {
  const center = map.map.getCenter()
  const zoom = map.map.getZoom()
  if (center) {
    saveMapView('store', { latitude: center.lat(), longitude: center.lng(), zoom })
  }
}

function updateLinkQueryParams (params: storeApi.SearchParams) {
  const queryParams = new URLSearchParams()
  if (params.keyword) { queryParams.append('store_search_form[keyword]', params.keyword) }
  if (params.communityId) { queryParams.append('store_search_form[community_id]', String(params.communityId)) }
  if (params.categoryId) { queryParams.append('store_search_form[category_id]', String(params.categoryId)) }
  if (params.otherCategory) { queryParams.append('store_search_form[other_category]', String(params.otherCategory)) }

  for (const el of document.querySelectorAll<HTMLAnchorElement>('.js-store-nav-link')) {
    updateAnchorQueryParams(el, queryParams)
  }
}

export function StoreMap ({ apiKey, fallbackLocation, categories, communities, pinnedStoreId, initialSearchParams }: Props) {
  const mapElRef = useRef<HTMLDivElement>(null)
  const mapRef = useRef<Map>()
  const fetchTimerRef = useRef<Array<ReturnType<typeof setTimeout>>>([])
  const [activities, setActivities] = useState('')

  async function handleSearchSubmit (params: storeApi.SearchParams) {
    const marking = _markStores(params)
    _fetchActivities(params)
    updateLinkQueryParams(params)

    const markers = await marking
    if (!markers) { return }
    if (markers.length > 0) {
      mapRef.current?.panToNearestMarker()
    } else {
      notice('該当する店舗は見つかりませんでした。')
    }
  }

  async function _markStores (params?: storeApi.SearchParams): Promise<Marker[] | undefined> {
    if (mapRef.current) {
      const markers = await markStores(mapRef.current, params || {})
      mapRef.current.renderMarkers()
      return markers
    }
  }

  async function _fetchActivities (params?: storeApi.SearchParams) {
    if (mapRef.current) {
      const content = await fetchActivities(mapRef.current, params || {})
      setActivities(content)
    }
  }

  function _pinStore (storeId: number) {
    if (mapRef.current) {
      mapRef.current.pinMarker({ id: String(storeId) })
    }
  }

  function handleBoundsChanged (_bounds: google.maps.LatLngBoundsLiteral) {
    // NOTE: 簡易 debounce
    for (const timer of fetchTimerRef.current) { clearTimeout(timer) }

    // NOTE: マーカーは目につきやすいので早めに再描画する
    fetchTimerRef.current = [
      setTimeout(() => mapRef.current?.renderMarkers(), 250),
      setTimeout(() => _fetchActivities(), 1000)
    ]
  }

  useEffect(() => {
    const fn = async () => {
      const map = await newMap(apiKey, mapElRef.current!, mapOptions(lastMapView('store') || fallbackLocation))
      map.onBoundsChanged(handleBoundsChanged)
      mapRef.current = map
      if (initialSearchParams) {
        handleSearchSubmit(initialSearchParams)
      } else {
        await _markStores()
        if (pinnedStoreId) {
          _pinStore(pinnedStoreId)
        }
      }
    }
    fn()

    return () => {
      mapRef.current?.dispose()
    }
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      if (mapRef.current) {
        saveCurrentMapView(mapRef.current)
      }
    }, 2000)
    return () => { clearInterval(interval) }
  }, [])

  return (
    <div className="absolute inset-0 flex flex-col">
      <HeadControls>
        <StoreSearchForm
          onSubmit={handleSearchSubmit}
          categories={categories}
          communities={communities}
          initialValues={initialSearchParams || {}}
        />
      </HeadControls>
      <div className="grow" ref={mapElRef} />
      <BottomSheet>
        <Timeline html={activities} />
      </BottomSheet>
    </div>
  )
}

function HeadControls ({ children }: { children: ReactNode }) {
  return (
    // NOTE: タイムラインより上位に表示されるよう z-index を指定している
    <div className="shrink px-3 pb-5 z-20 bg-white shadow-md">
      { children }
    </div>
  )
}

function BottomSheet ({ children }: { children: ReactNode }) {
  const [open, setOpen] = useState(false)
  const swipeDetectorRef = useRef<SwipeDetector>(new SwipeDetector())

  function handleClick () {
    setOpen(v => !v)
  }
  function handleTouchStart (ev: TouchEvent) {
    const { pageX, pageY } = ev.touches[0]
    swipeDetectorRef.current!.handleTouchStart(pageX, pageY)
  }
  function handleTouchMove (ev: TouchEvent) {
    const { pageX, pageY } = ev.touches[0]
    swipeDetectorRef.current!.handleTouchMove(pageX, pageY)
  }
  function handleTouchEnd () {
    const result = swipeDetectorRef.current!.handleTouchEnd()
    switch (result) {
      case 'swiped-down':
        setOpen(false)
        break
      case 'swiped-up':
        setOpen(true)
        break
      default:
        break
    }
  }

  return (
    <div className={`mx-2 bottom-sheet ${open ? 'bottom-sheet--open' : ''}`}>
      {/* NOTE: カルーセルの画像にかぶらないよう z-index を指定している */}
      <div
        onClick={handleClick}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        className="sticky top-0 text-center pt-2 pb-5 bg-white z-10 rounded-t-xl"
      >
        <button type="button" className="text-gray-500">
          <i className={`far ${open ? 'fa-chevron-down' : 'fa-chevron-up'}`} />
        </button>
        <div className="mt-1">
          地域の最新タイムライン
        </div>
      </div>
      <div className="bottom-sheet-main-content">
        {children}
      </div>
    </div>
  )
}

function Timeline ({ html }: { html: string }) {
  const activities = html.trim()
  return (
    <div className="p-5">
      {
        activities.length > 0
          ? (
          <div className="space-y-10" dangerouslySetInnerHTML={{ __html: html }} />
            )
          : (
          <div className="text-center text-gray-400 text-sm no-mt">範囲内に店舗がありません。地図を動かして店舗を探してみてください。</div>
            )
      }
    </div>
  )
}
