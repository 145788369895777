import { onConnect } from '@sonicgarden/onconnect'

onConnect('.js-loading', (el) => {
  el.addEventListener('submit', handleClick)

  return () => {
    loadingSwitch(false)
  }
})

function handleClick (event: SubmitEvent) {
  loadingSwitch(true)
}

function loadingSwitch (show: boolean) {
  const loading = document.querySelector<HTMLElement>('.js-loading-area')
  if (loading) {
    loading.style.display = show ? 'flex' : 'none'
  }
}
