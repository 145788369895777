import * as screen from './screen'
import * as mobileBridge from './mobile_bridge'
import * as pullToRefresh from './pull_to_refresh'
import * as balanceCalculator from '../shared/balance_calculator'
import * as dropdown from './dropdown'
import './elements'
import './tabs'
import './scroll_into_view'
import './read_more'
import './fill_button'
import './image_upload_form'
import './credit_card_form'
import './sign_in_background'
import './credit_topup_calculator'
import './custom_validation_message'
import './user_community_stat'
import './confirmation_checkbox'
import './copy_fields'
import './zip_code_auto_fill'
import './long_select_options'
import './play_sound'
import './loading'

function load () {
  screen.init()
  mobileBridge.init()
  pullToRefresh.init()
  balanceCalculator.init()
  dropdown.init()
}

function beforeCache () {
  screen.destroy()
  pullToRefresh.destroy()
  dropdown.destroy()
}

document.addEventListener('turbo:load', load)
document.addEventListener('turbo:before-cache', beforeCache)
