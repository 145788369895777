import { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/pagination'

type ImageProps = {
  src: string;
  width?: number;
  height?: number;
  style: 'cover' | 'contain' | 'fill';
}

type Props = {
  images: ImageProps[]
}

export function Carousel ({ images }: Props) {
  if (images.length === 1) {
    return (
      <CarouselImage {...images[0]} />
    )
  }

  return (
    <Swiper
      modules={[Pagination]}
      pagination
      className="h-full"
    >
      {
        images.map((img) => (
          <SwiperSlide key={img.src}>
            <CarouselImage {...img} />
          </SwiperSlide>
        ))
      }
    </Swiper>
  )
}

function CarouselImage ({ src, width, height, style }: ImageProps) {
  // NOTE: h-full なのでレイアウトシフト対策がきいてない。h-auto にすると店舗トップが崩れてしまうので要対策
  return (
    <img src={src} className={`h-full w-full ${objectFitClass(style)}`} width={width} height={height} />
  )
}

function objectFitClass (style: ImageProps['style']) {
  switch (style) {
    case 'contain':
      return 'object-contain'
    case 'cover':
      return 'object-cover'
    case 'fill':
      return 'object-fill'
  }
}
