import { useState } from 'react'
import { Transition } from '@headlessui/react'
import { useTimeout } from 'libs/hooks'

type MessageType = 'notice' | 'alert'

type Props = {
  type: MessageType
  message: string
}

// SEE: https://tailwindui.com/components/application-ui/overlays/notifications#component-a065edf8f08a35f06943b2e266ca9af1
export function Flash ({ type, message }: Props) {
  const icon = type === 'notice' ? 'fa-check-circle' : 'fa-exclamation-circle'
  const color = type === 'notice' ? 'text-green-300' : 'text-red-300'
  const timeout = type === 'notice' ? 3000 : 6000
  const [isOpen, setIsOpen] = useState(true)

  function handleClose () {
    setIsOpen(false)
  }
  useTimeout(timeout, handleClose)

  return (
    <FlashMessage
      icon={icon} color={color} message={message} isOpen={isOpen} onClose={handleClose}
    />
  )
}

type MessageProps = {
  icon: string
  color: string
  message: string
  isOpen: boolean
  onClose: () => void
}

function FlashMessage ({ icon, color, message, isOpen, onClose }: MessageProps) {
  return (
    <Transition
      show={isOpen}
      enter="ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="bg-gray-600 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
        <div className="p-4">
          <div className="flex items-center">
            <div className="shrink-0">
              <i className={`far ${icon} ${color}`} />
            </div>
            <div className="ml-3 w-0 flex-1 text-gray-50 text-sm">
              {message}
            </div>
            <div className="ml-4 shrink-0 flex">
              <button className="bg-gray-600 rounded-md inline-flex text-gray-100 hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500" onClick={onClose}>
                <i className="far fa-times" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  )
}
