import { onConnect } from '@sonicgarden/onconnect'

onConnect('.js-long-select-options', (el) => {
  bless(el)

  return () => {
    unbless(el)
  }
})

function bless (el: HTMLElement) {
  // SEE: https://qiita.com/yukinaka/items/89bc9cf7c9dd58ee9692
  el.insertAdjacentHTML('beforeend', '<optgroup label="" class="js-fix-long-select-options"></optgroup>')
}

function unbless (el: HTMLElement) {
  el.querySelector('.js-fix-long-select-options')?.remove()
}
