import { isAndroid } from 'libs/user_agent'

export function init () {
  if (isAndroid()) {
    return
  }

  recalculateScreenHeight()
  window.addEventListener('resize', recalculateScreenHeight)
}

export function destroy () {
  window.removeEventListener('resize', recalculateScreenHeight)
}

function recalculateScreenHeight () {
  const root = document.querySelector<HTMLElement>(':root')
  if (!root) { return }

  root.style.setProperty('--screen-vh', `${window.innerHeight}px`)
}
