import { onConnectedElementEvent } from 'libs/event'

onConnectedElementEvent('.js-custom-validation-message', 'invalid', handleInvalid)
onConnectedElementEvent('.js-custom-validation-message', 'input', handleInput)
onConnectedElementEvent('.js-custom-validation-message', 'change', handleInput)

function handleInput (ev: Event) {
  const el = ev.currentTarget as HTMLInputElement
  if (!el) { return }

  el.setCustomValidity('')
}

function handleInvalid (ev: Event) {
  const el = ev.currentTarget as HTMLInputElement
  if (!el) { return }

  if (el.validity.rangeUnderflow) {
    showCustomValidationMessage(el, 'range-underflow', { value: el.min })
  }
  if (el.validity.rangeOverflow) {
    showCustomValidationMessage(el, 'range-overflow', { value: el.max })
  }
}

function showCustomValidationMessage (el: HTMLInputElement, type: string, options: { [key: string]: any }) {
  const template = el.getAttribute(`data-${type}-message-template`)
  if (!template) { return }

  const message = Object.entries(options).reduce((msg, [k, v]) => msg.replace(`%{${k}}`, v), template)
  el.setCustomValidity(message)
}
