import { useState } from 'react'
import { copyToClipboard } from 'libs/clipboard'

type Props = {
  text: string
}

export function CopyButton ({ text }: Props) {
  const [copied, setCopied] = useState(false)

  async function handleClick () {
    await copyToClipboard(text)
    setCopied(true)
  }

  const title = copied ? 'コピーしました' : 'コピーする'
  return (
    <button type="button" className="border rounded py-1 px-2 bg-gray-50 hover:bg-gray-100 focus:ring-2 focus:ring-gray-100" title={title} onClick={handleClick}>
      <i className={`far ${copied ? 'fa-check text-green-600' : 'fa-clipboard text-gray-600'}`} />
    </button>
  )
}
