import Swiper from 'swiper'
import { onConnect } from '@sonicgarden/onconnect'
import { UserCommunityStat } from 'libs/user_community_stat'

onConnect('.js-user-community-stats', (el) => {
  const swiper = new Swiper(el, {
    slidesPerView: 1.5,
    centeredSlides: true
  })
  const stats = initStats(el)

  return () => {
    swiper.destroy()
    for (const stat of stats) {
      stat.destroy()
    }
  }
})

function initStats (el: HTMLElement): UserCommunityStat[] {
  const stats: UserCommunityStat[] = []
  for (const statEl of el.querySelectorAll<HTMLElement>('.js-user-community-stat')) {
    const stat = new UserCommunityStat(statEl)
    stats.push(stat)
    stat.watch()
  }
  return stats
}
