import { useUploadImages, ImageStatus, UploadedImage } from 'libs/hooks/use_upload_images'
import { Spinner } from './spinner'

type Props = {
  name: string
  path: string
  images?: UploadedImage[]
}

export function UploadMultipleImages ({ name, path, images: uploadedImages }: Props) {
  const { images, handleDelete, handleFileChange } = useUploadImages(path, uploadedImages || [])

  const imagesElements = images.length > 0
    ? images.map((img) => <ImageView key={img.id} name={name} image={img} onDelete={handleDelete} />)
    : <input type="hidden" name={name} value="" />
  return (
    <>
      <div className="grid grid-cols-4 gap-x-4 gap-y-5 mb-4 py-2">
        {imagesElements}
      </div>
      <div className="relative">
        <label htmlFor={name} className="absolute inset-0">
          <i className="far fa-paperclip mr-3" />
          <span>画像を追加する</span>
        </label>
        <input
          id={name}
          type="file"
          onChange={handleFileChange}
          style={{ visibility: 'hidden', height: 0 }}
          multiple={true}
          accept="image/*"
        />
      </div>
    </>
  )
}

type ImageViewProps = {
  name: string
  image: ImageStatus
  onDelete: (id: number) => void
}

function ImageView ({ name, image, onDelete }: ImageViewProps) {
  function handleClick () {
    onDelete(image.id)
  }

  return (
    <div className="flex items-center justify-center w-full h-16 relative">
      <button type="button" className="absolute flex items-center justify-center -top-2 -right-2 w-5 h-5 border-2 border-gray-400 rounded-full bg-white bg-opacity-90" onClick={handleClick}>
        <i className="far fa-times" />
      </button>
      {
        (() => {
          switch (image.status) {
            case 'uploading':
              return <UploadingImageView />
            case 'uploaded':
              return <UploadedImageView name={name} {...image.data} />
            case 'error':
              return <ErroredImageView />
          }
        })()
      }
    </div>
  )
}

function UploadingImageView () {
  return (
    <span data-uploading={true}>
      <Spinner />
    </span>
  )
}

type UploadedImageProps = UploadedImage & { name: string }

function UploadedImageView ({ name, signedId, url }: UploadedImageProps) {
  return (
    <>
      <img src={url} className="h-full w-full object-contain" />
      <input type="hidden" name={name} value={signedId} />
    </>
  )
}

function ErroredImageView () {
  return (
    <div className="flex flex-col items-center text-red-600">
      <i className="far fa-times" />
      <span style={{ fontSize: '0.5rem' }}>エラー</span>
    </div>
  )
}
