export class Drawer {
  overlayElement: Element
  menuElement: Element

  constructor (
    overlayElement: Element,
    menuElement: Element
  ) {
    this.overlayElement = overlayElement
    this.menuElement = menuElement
  }

  show = () => {
    toggleClass(this.overlayElement, { remove: 'hidden' })
    toggleClass(this.menuElement, { add: 'translate-x-0', remove: '-translate-x-full' })
  }

  hide = () => {
    toggleClass(this.overlayElement, { add: 'hidden' })
    toggleClass(this.menuElement, { add: '-translate-x-full', remove: 'translate-x-0' })
  }
}

function toggleClass (element: Element, { add, remove }: { add?: string, remove?: string }) {
  const { classList } = element
  if (add) { classList.add(add) }
  if (remove) { classList.remove(remove) }
}
