import { onConnect } from '@sonicgarden/onconnect'

onConnect('form button', (el) => {
  if (!isSubmitButton(el)) { return }
  if (hasDisableAttribute(el)) { return }

  el.setAttribute('data-disable', 'true')
})

function isSubmitButton (el: HTMLElement) {
  const type = el.getAttribute('type')
  return !type || type === 'submit'
}

function hasDisableAttribute (el: HTMLElement) {
  return el.hasAttribute('data-disable') || el.hasAttribute('data-disable-with')
}
