import { alert, notice } from 'libs/flash'
import type { TurboFrameRenderEvent } from '@hotwired/turbo'

export function init () {
  document.addEventListener('turbo:frame-render', handleFrameRender)
}

export function destroy () {
  document.removeEventListener('turbo:frame-render', handleFrameRender)
}

function handleFrameRender (event: Event) {
  const json = (event as TurboFrameRenderEvent).detail.fetchResponse.header('X-Flash-Messages')
  if (!json) { return }

  const flashData = JSON.parse(json)
  for (const [type, message] of Object.entries(flashData)) {
    showMessage(type, message as string)
  }
}

function showMessage (type: string, message: string) {
  switch (type) {
    case 'alert':
      alert(message)
      break
    case 'notice':
      notice(message)
      break
  }
}
