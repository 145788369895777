import { MouseEvent, SetStateAction, useState } from 'react'
import { loadMultipayment, codeToErrorMessage } from 'libs/gmo'

type Props = {
  tokenInputId: string
  shopId: string
}

function useInput (initialValue: string) {
  const [value, set] = useState(initialValue)

  function onChange (e: { target: { value: SetStateAction<string> } }) {
    set(e.target.value)
  }

  return { value, onChange }
}

function concatExpire (month: string, year: string): string {
  const m = Number(month)
  const y = Number(year)
  return `${y}${m < 10 ? `0${m}` : m}`
}

export function CreditCard ({ tokenInputId, shopId }: Props) {
  const cardNumber = useInput('')
  const cardExpireMm = useInput('')
  const cardExpireYy = useInput('')
  const cardSecurityCode = useInput('')
  const cardHolderName = useInput('')
  const [errorMessage, setErrorMessage] = useState('')

  function handleRegisterCreditCard (e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault()

    const mulpay = loadMultipayment()
    mulpay.init(shopId)
    // callbackを関数で渡すとstaging, productionでwebpackが関数名を難読化するため、文字列で渡す
    mulpay.getToken({
      cardno: cardNumber.value,
      expire: concatExpire(cardExpireMm.value, cardExpireYy.value),
      securitycode: cardSecurityCode.value,
      holdername: cardHolderName.value
    }, 'execRegisterCreditCard')
  }

  function execRegisterCreditCard (response: any) {
    if (response.resultCode === '000') {
      const el = document.getElementById(tokenInputId) as HTMLInputElement | null
      if (el) {
        el.value = response.tokenObject.token
        el.dispatchEvent(new Event('change'))
      }
    } else {
      const errorMessage = codeToErrorMessage(response.resultCode)
      setErrorMessage(`${errorMessage}\nエラーコード: ${response.resultCode}`)
    }
  }
  // GMOからのコールバックを受け取るためにグローバルにセットする
  window.execRegisterCreditCard = execRegisterCreditCard

  return (
    <div>
      {
        errorMessage.length > 0 && (
          <div className="rounded p-3 bg-red-100 text-red-600 text-sm mb-3">
            <div className="font-bold">クレジットカードを登録できませんでした</div>
            <div className="mt-1 whitespace-pre-wrap">{ errorMessage }</div>
          </div>
        )
      }
      <fieldset className="bg-gray-50 rounded p-4">
        <div className="form-group">
          <label htmlFor="card-number" className="form-label">カード番号</label>
          <input type="tel" name="card-number" id="card-number"
                 className="form-control" maxLength={16} autoComplete="cc-number" {...cardNumber} />
        </div>
        <div className="form-group">
          <label htmlFor="card-holder-name" className="form-label">ご契約者氏名</label>
          <input type="text" name="card-holder-name" id="card-holder-name"
                 className="form-control" autoComplete="cc-name" {...cardHolderName} />
        </div>
        <div className="form-group grid grid-cols-2 gap-3">
          <div>
            <label className="form-label">有効期限</label>
            <div className="flex items-center mt-2">
              <input type="tel" name="card-expire-month" id="card-expire-month"
                     className="form-control grow"
                     placeholder="月" maxLength={2} autoComplete="cc-exp-month" {...cardExpireMm} />
              <span className="shrink mx-1">/</span>
              <input type="tel" name="card-expire-year" id="card-expire-year"
                     className="form-control grow"
                     placeholder="年" maxLength={2} autoComplete="cc-exp-year" {...cardExpireYy} />
            </div>
          </div>
          <div>
            <label htmlFor="card-cvc" className="form-label">CVC</label>
            <input type="tel" name="card-cvc" id="card-cvc"
                className="form-control" maxLength={4} autoComplete="cc-csc" {...cardSecurityCode} />
          </div>
        </div>
        <div className="mt-4 text-sm text-gray-600">
          利用可能なブランドは VISA, MasterCard, JCB, AMEX です。
        </div>
      </fieldset>
      <button className="btn btn-rounded btn-block btn-purple mt-5" onClick={handleRegisterCreditCard}>クレジットカードを登録する</button>
    </div>
  )
}
