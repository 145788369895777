import { onConnectedElementEvent } from 'libs/event'

onConnectedElementEvent('a.js-tab-link', 'click', handleClick)

function handleClick (event: MouseEvent) {
  const { currentTarget } = event
  if (!currentTarget) { return }

  event.preventDefault()

  const link = currentTarget as HTMLElement
  const tabContent = document.querySelector<HTMLElement>(link.getAttribute('href')!)
  const tabMenu = link.closest<HTMLElement>('.tab-menu')
  if (!tabMenu || !tabContent) { return }

  activateTab(tabMenu)
  activateTabContent(tabContent)
}

function activateTab (tab: HTMLElement) {
  for (const el of tab.parentNode!.children) {
    if (el === tab) {
      el.classList.add('active')
    } else {
      el.classList.remove('active')
    }
  }
}

function activateTabContent (tabContent: HTMLElement) {
  for (const el of tabContent.parentNode!.children) {
    if (el === tabContent) {
      el.classList.remove('hidden')
    } else {
      el.classList.add('hidden')
    }
  }
}
