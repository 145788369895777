import { onConnectedElementEvent } from 'libs/event'

onConnectedElementEvent('.js-tooltip', 'pointerover', handlePointerOverTooltip)
onConnectedElementEvent('.js-tooltip', 'pointerout', handlePointerOutTooltip)

function handlePointerOverTooltip (ev: Event) {
  const el = ev.currentTarget as HTMLElement

  const tooltipMessage = el.dataset.tooltipMessage
  if (!tooltipMessage) { return }
  console.log(tooltipMessage)

  const tooltip = document.createElement('div')
  tooltip.classList.add('js-tooltip-element', 'admin-tooltip')
  tooltip.textContent = tooltipMessage

  var rect = el.getBoundingClientRect()
  console.log(rect.top, rect.right, rect.bottom, rect.left)
  console.log((rect.top - window.scrollY))
  console.log((window.scrollY))
  console.log((rect.top))

  document.body.appendChild(tooltip)
  tooltip.style.left = (rect.left - tooltip.clientWidth / 2 + el.clientWidth / 2) + 'px'
  tooltip.style.top = (rect.top - tooltip.clientHeight - 10) + 'px'
}

function handlePointerOutTooltip (ev: Event) {
  for (const targetElement of document.querySelectorAll<HTMLInputElement>('.js-tooltip-element')) {
    targetElement.remove()
  }
}
