import { onConnectedElementEvent } from 'libs/event'

onConnectedElementEvent('.js-fill-button', 'click', fill)

function fill (event: MouseEvent) {
  const { currentTarget } = event
  const button = currentTarget as HTMLElement
  const target = button.dataset.fillTarget
  const value = button.dataset.fillValue
  if (!target || !value) { return }

  const el = document.querySelector<HTMLInputElement>(target)
  if (!el) { return }

  el.value = value
  el.dispatchEvent(new Event('change'))
}
