import { onConnect } from '@sonicgarden/onconnect'
import { ImageUploadForm } from 'libs/image_upload_form'

onConnect('x-upload-multiple-images, x-upload-single-image', (el) => {
  const formEl = el.closest<HTMLFormElement>('form')
  if (!formEl) { return }

  const form = new ImageUploadForm(formEl)
  form.start()

  return () => {
    form.stop()
  }
})
