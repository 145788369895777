import { rewrap } from '@sonicgarden/rewrap'
import {
  Flash,
  Carousel,
  ShowPasswordLabel,
  LoadNext,
  OpenDialog,
  CopyButton,
  UploadMultipleImages,
  ExpandableText,
  UploadSingleImage,
  Dialog
} from 'components/shared'

rewrap('x-flash', Flash)
rewrap('x-carousel', Carousel)
rewrap('x-show-password-label', ShowPasswordLabel)
rewrap('x-load-next', LoadNext)
rewrap('x-open-dialog', OpenDialog, true)
rewrap('x-copy-button', CopyButton)
rewrap('x-upload-multiple-images', UploadMultipleImages)
rewrap('x-expandable-text', ExpandableText)
rewrap('x-upload-single-image', UploadSingleImage)
rewrap('x-dialog', Dialog, true)
