type Result = 'swiped-left' | 'swiped-right' | 'swiped-up' | 'swiped-down'

const THRESHOLD = 20
const TIMEOUT = 500

// SEE: https://github.com/john-doherty/swiped-events/blob/d2f10d1f1bc7544ee62102a906e1d1f1225f1e64/src/swiped-events.js
export class SwipeDetector {
  xDown: number = 0
  yDown: number = 0
  xDiff: number = 0
  yDiff: number = 0
  timeDown: number = 0

  handleTouchStart (pageX: number, pageY: number) {
    this.timeDown = Date.now()
    this.xDown = pageX
    this.yDown = pageY
    this.xDiff = 0
    this.yDiff = 0
  }

  handleTouchMove (pageX: number, pageY: number) {
    if (!this.xDown || !this.yDown) { return }

    this.xDiff = this.xDown - pageX
    this.yDiff = this.yDown - pageY
  }

  handleTouchEnd (): Result | null {
    const timeDiff = Date.now() - this.timeDown
    let result: Result | null = null
    const horizontal = Math.abs(this.xDiff) > Math.abs(this.yDiff)
    const moveDiff = horizontal ? this.xDiff : this.yDiff
    if (Math.abs(moveDiff) > THRESHOLD && timeDiff < TIMEOUT) {
      if (horizontal) {
        result = moveDiff > 0 ? 'swiped-left' : 'swiped-right'
      } else {
        result = moveDiff > 0 ? 'swiped-up' : 'swiped-down'
      }
    }

    this.xDown = 0
    this.yDown = 0
    this.timeDown = 0
    return result
  }
}
