export function alert (message: string) {
  showMessage('alert', message)
}

export function notice (message: string) {
  showMessage('notice', message)
}

function showMessage (type: 'notice' | 'alert', message: string) {
  const wrapper = document.querySelector<HTMLElement>('.js-flash')
  if (!wrapper) return

  const el = document.createElement('x-flash')
  el.classList.add('js-one-off')
  el.setAttribute('data-props', JSON.stringify({ type, message }))
  wrapper.appendChild(el)
}
