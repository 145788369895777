type AndroidJsInterface = any

declare global {
  interface Window { // eslint-disable-line no-unused-vars
    ANDROID: AndroidJsInterface;
  }
}

export class AndroidBridge {
  scanQrCode () {
    this.android.scanQrCode()
  }

  scanQrCodeCardTransfer () {
    return this.call('scanQrCodeCardTransfer')
  }

  playSound () {
    this.android.playSound()
  }

  sendText (text: string) {
    this.android.sendText(text)
  }

  download (url: string, filename: string) {
    this.android.download(url, filename)
  }

  startGetCurrentLocation (): boolean {
    return this.call('startGetCurrentLocation')
  }

  call (fn: string, ...args: string[]): boolean {
    if (typeof this.android[fn] === 'function') {
      this.android[fn](...args)
      return true
    } else {
      return false
    }
  }

  get android (): AndroidJsInterface {
    return window.ANDROID
  }
}
