import { client } from 'libs/ajax'
import Chart, { ChartDataset, DefaultDataPoint } from 'chart.js/auto'

type StatChartDataset = ChartDataset<'doughnut', DefaultDataPoint<'doughnut'>>

type StatData = {
  sentTransfer: number;
  gift: number;
  expired: number;
  receivedTransfer: number;
  giveBack: number;
}

// SEE: https://stackoverflow.com/questions/28569667/fill-chart-js-bar-chart-with-diagonal-stripes-or-other-patterns
function createDiagonalPattern (color: string): CanvasPattern {
  const shape = document.createElement('canvas')
  shape.width = 10
  shape.height = 10
  const c = shape.getContext('2d')!
  c.strokeStyle = color
  // draw 1st line of the shape
  for (let x = 1; x < 7; ++x) {
    c.beginPath()
    c.moveTo(10 - x, 0)
    c.lineTo(0, 10 - x)
    c.stroke()
  }
  // draw 2nd line of the shape
  for (let y = 4; y < 10; ++y) {
    c.beginPath()
    c.moveTo(10, y)
    c.lineTo(y, 10)
    c.stroke()
  }
  return c.createPattern(shape, 'repeat')!
}

class StatChart {
  data: StatData
  canvas: HTMLCanvasElement
  chart: Chart | null = null

  constructor (data: StatData, canvas: HTMLCanvasElement) {
    this.data = data
    this.canvas = canvas
  }

  render () {
    this.chart = new Chart(this.canvas, {
      type: 'doughnut',
      data: {
        datasets: [
          this.buildOuterDataset(),
          this.buildInnerDataset()
        ]
      },
      options: {
        // FIXME: ts4.9 になってコンパイルエラーが出るようになった。型エラーを解決できないのでコメントで回避
        // @ts-expect-error
        cutout: '65%',
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: false
          }
        }
      }
    })
  }

  buildOuterDataset (): StatChartDataset {
    const {
      sentTransfer,
      gift,
      expired,
      receivedTransfer,
      giveBack
    } = this.data
    return {
      data: [
        receivedTransfer + giveBack,
        sentTransfer + gift + expired
      ],
      backgroundColor: [
        createDiagonalPattern('#ffeb8a'),
        createDiagonalPattern('#a9fac7')
      ],
      weight: 0.75
    }
  }

  buildInnerDataset (): StatChartDataset {
    if (this.isEmpty()) {
      return {
        data: [
          1
        ],
        backgroundColor: [
          '#efefef'
        ],
        weight: 2
      }
    } else {
      const {
        sentTransfer,
        gift,
        expired,
        receivedTransfer,
        giveBack
      } = this.data
      return {
        data: [
          receivedTransfer,
          giveBack,
          expired,
          gift,
          sentTransfer
        ],
        backgroundColor: [
          '#0ddbf1',
          '#fe199b',
          '#062e6d',
          '#ae46e9',
          '#1e71f3'
        ],
        weight: 2
      }
    }
  }

  isEmpty (): boolean {
    return Object.values(this.data).every(v => v === 0)
  }

  destroy () {
    if (!this.chart) { return }

    this.chart.destroy()
    this.chart = null
  }
}

export class UserCommunityStat {
  el: HTMLElement
  chart: StatChart | null = null
  observer: IntersectionObserver | null = null

  constructor (el: HTMLElement) {
    this.el = el
  }

  watch () {
    let callbackCalled = false
    const callback = (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries
      if (entry.isIntersecting && !callbackCalled) {
        callbackCalled = true
        this.load()
      }
    }

    this.observer = new IntersectionObserver(callback)
    this.observer.observe(this.el)
  }

  async load () {
    const url = this.el.dataset.url
    if (!url) { return }

    const { data } = await client.get<StatData>(url)
    this.render(data)
  }

  destroy () {
    this.toggleCommunityLogo({ show: false })
    this.chart?.destroy()
    this.chart = null
    this.observer?.disconnect()
    this.observer = null
  }

  render (data: any) {
    this.chart = new StatChart(data, this.canvas)
    this.chart.render()
    this.toggleCommunityLogo({ show: true })
  }

  get canvas (): HTMLCanvasElement {
    return this.el.querySelector<HTMLCanvasElement>('.js-user-community-stat-canvas')!
  }

  toggleCommunityLogo ({ show }: { show: boolean }) {
    const img = this.el.querySelector<HTMLElement>('.js-user-community-stat-logo')
    img?.classList.toggle('hidden', !show)

    const spinner = this.el.querySelector<HTMLElement>('.js-user-community-stat-spinner')
    spinner?.classList.toggle('hidden', show)
  }
}
