import { onConnectedElementEvent } from 'libs/event'

onConnectedElementEvent('.js-check-all', 'click', handleClick)

function handleClick (event: Event) {
  const el = event.currentTarget as HTMLElement
  const targetSelector = el.dataset.checkAllTarget
  if (!targetSelector) { return }

  for (const checkbox of document.querySelectorAll<HTMLInputElement>(targetSelector)) {
    checkbox.checked = true
  }
}
