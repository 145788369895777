import { onConnectedElementEvent } from 'libs/event'
import { findElementByDataAttr } from 'libs/dom'

onConnectedElementEvent('.js-copy-fields', 'click', handleClick)

function handleClick (ev: Event) {
  const button = ev.currentTarget as HTMLElement
  const src = findElementByDataAttr(button, 'copySrc')
  const dest = findElementByDataAttr(button, 'copyDest')
  if (!src || !dest) { return }

  copyFields(src, dest)
}

function copyFields (src: HTMLElement, dest: HTMLElement) {
  for (const srcInput of src.querySelectorAll<HTMLInputElement>('input[data-name]')) {
    copyInput(srcInput, dest)
  }
  for (const srcSelect of src.querySelectorAll<HTMLSelectElement>('select[data-name]')) {
    copySelect(srcSelect, dest)
  }
}

function copyInput (srcInput: HTMLInputElement, dest: HTMLElement) {
  const name = srcInput.dataset.name!
  const destInput = findElementByDataName<HTMLInputElement>(dest, name)
  if (destInput) {
    destInput.value = srcInput.value
  }
}

function copySelect (srcSelect: HTMLSelectElement, dest: HTMLElement) {
  const name = srcSelect.dataset.name!
  const destSelect = findElementByDataName<HTMLSelectElement>(dest, name)
  if (destSelect) {
    destSelect.value = srcSelect.value
  }
}

function findElementByDataName<T extends Element> (el: HTMLElement, name: string) {
  return el.querySelector<T>(`[data-name="${name}"]`)
}
