import { mobileBridge } from 'libs/mobile_bridge'
import { withLocalStorage } from 'libs/storage'
import { alert } from 'libs/flash'

export type LatLng = {
  latitude: number;
  longitude: number;
}

export type MapView = LatLng & { zoom?: number }

export type Bounds = {
  east: number;
  north: number;
  west: number;
  south: number;
}

function keyOf (prefix: string): string {
  return `__${prefix}_last_location`
}

export function lastMapView (key: string): MapView | null {
  const val = withLocalStorage(s => s.getItem(keyOf(key)))
  if (!val) { return null }

  try {
    return JSON.parse(val) as MapView
  } catch {
    withLocalStorage(s => s.removeItem(keyOf(key)))
    return null
  }
}

export function saveMapView (key: string, view: MapView) {
  withLocalStorage(s => s.setItem(keyOf(key), JSON.stringify(view)))
}

type LocationHandler = (location: LatLng | null) => void

export class Locator {
  handler: LocationHandler | null = null

  dispose () {
    this.handler = null
    if (lastLocator === this) {
      lastLocator = null
    }
  }

  start (handler: LocationHandler): boolean {
    if (mobileBridge.native.startGetCurrentLocation()) {
      lastLocator = this
      this.handler = handler
      return true
    } else {
      return false
    }
  }

  handleGet (location: LatLng | null) {
    if (this.handler) {
      this.handler(location)
    }
  }
}

let lastLocator: Locator | null = null

export function handleGetCurrentLocation (location: LatLng | null, error?: string) {
  if (lastLocator) {
    lastLocator.handleGet(location)
    if (error) {
      alert(`現在地を取得できませんでした: ${error}`)
    }
  }
}
