import { onConnectedElementEvent } from 'libs/event'

onConnectedElementEvent('#js-credit-card-token', 'change', (ev) => {
  const { currentTarget } = ev
  if (!currentTarget) { return }

  const input = currentTarget as HTMLInputElement
  if (input.value.length > 0) {
    const el = document.querySelector<HTMLFormElement>('#js-credit-card-form')
    el?.submit()
  }
})
