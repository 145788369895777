import { useRef, useState, useEffect, FormEvent, ChangeEvent, MouseEvent, ReactNode } from 'react'
import type { SearchParams } from 'libs/http/store'

type StoreCategoryProps = {
  id: number | null;
  name: string;
  icon: string;
}

type CommunityProps = {
  id: number;
  name: string;
  logo: string;
}

type SearchFormProps = {
  initialValues?: Partial<SearchParams>;
  onSubmit: (params: SearchParams) => void;
  categories: StoreCategoryProps[];
  communities: CommunityProps[];
  keepDetailOpened?: boolean;
  supportOnlineShoppingAvailable?: boolean;
}

type SearchState = { [k: string]: string | null }

function getValueAs<T> (state: SearchState, name: string, mapper: (value: string) => T): T | null {
  const val = state[name]
  if (!val) { return null }

  return mapper(val)
}

function toSearchParams (state: SearchState): SearchParams {
  return {
    keyword: getValueAs(state, 'keyword', String),
    communityId: getValueAs(state, 'community', Number),
    categoryId: getValueAs(state, 'category', (v) => v !== 'other' ? Number(v) : null),
    otherCategory: getValueAs(state, 'category', (v) => v === 'other'),
    onlineShoppingAvailable: getValueAs(state, 'onlineShoppingAvailable', (v) => v === 'true'),
    furusatoMoneyAvailable: getValueAs(state, 'furusatoMoneyAvailable', (v) => v === 'true'),
    cardUsable: getValueAs(state, 'cardUsable', (v) => v === 'true')
  }
}

function toSearchState (values: Partial<SearchParams> | undefined): SearchState {
  return {
    keyword: values?.keyword || null,
    community: values?.communityId ? String(values.communityId) : null,
    category: values?.otherCategory ? 'other' : (values?.categoryId ? String(values.categoryId) : null),
    onlineShoppingAvailable: values?.onlineShoppingAvailable ? 'true' : '',
    furusatoMoneyAvailable: values?.furusatoMoneyAvailable ? 'true' : '',
    cardUsable: values?.cardUsable ? 'true' : ''
  }
}

export function StoreSearchForm (props: SearchFormProps) {
  const { initialValues, onSubmit, categories, communities, keepDetailOpened, supportOnlineShoppingAvailable } = props
  const [values, setValues] = useState<SearchState>(toSearchState(initialValues))
  const [submitOnNextRender, setSubmitOnNextRender] = useState(false)

  function handleSubmit () {
    onSubmit(toSearchParams(values))
    setSubmitOnNextRender(false)
  }

  function handleChange (name: string, value: string | null, submit?: boolean) {
    setValues({ ...values, [name]: value })
    if (submit) {
      setSubmitOnNextRender(true)
    }
  }

  function renderCategoryLabel (category: StoreCategoryProps) {
    return (
      <CategoryLabel
        key={category.id}
        selected={values.category}
        onChange={handleChangeCategory}
        {...category}
      />
    )
  }

  function renderCommunityLabel (community: CommunityProps) {
    return (
      <CommunityLabel
        key={community.id}
        selected={values.community}
        onChange={handleChangeCommunity}
        {...community}
      />
    )
  }

  useEffect(() => {
    if (submitOnNextRender) {
      handleSubmit()
    }
  }, [submitOnNextRender])

  const handleChangeCommunity = handleChange.bind(null, 'community')
  const handleChangeCategory = handleChange.bind(null, 'category')
  return (
    <StoreSearchFormView
      onSubmit={handleSubmit}
      onValueChanged={handleChange}
      values={values}
      keepDetailOpened={!!keepDetailOpened}
      supportOnlineShoppingAvailable={!!supportOnlineShoppingAvailable}
      renderDetail={() => (
        <DetailSearchContainer>
          <div className="mt-2 grid grid-cols-2 gap-x-2 gap-y-2">
            {categories.map(renderCategoryLabel)}
          </div>
          <hr className="my-5" />
          <div className="text-center font-bold text-lg">
            通貨（コミュニティ）で探す
          </div>
          <div className="mt-2 grid grid-cols-2 gap-x-2 gap-y-2">
            {communities.map(renderCommunityLabel)}
          </div>
        </DetailSearchContainer>
      )}
    />
  )
}

type StoreSearchFormViewProps = {
  onSubmit: () => void;
  onValueChanged: (name: string, value: string | null, submit?: boolean) => void;
  values: SearchState;
  keepDetailOpened: boolean;
  supportOnlineShoppingAvailable: boolean;
  renderDetail: () => ReactNode
}

function StoreSearchFormView (props: StoreSearchFormViewProps) {
  const { onSubmit, onValueChanged, values, keepDetailOpened, supportOnlineShoppingAvailable, renderDetail } = props
  const [detail, setDetail] = useState(false)
  const formRef = useRef<HTMLFormElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const windowHeightRef = useRef<number>()

  function handleSubmit (ev: FormEvent<HTMLFormElement>) {
    ev.preventDefault()
    inputRef.current!.blur()
    if (!keepDetailOpened) {
      setDetail(false)
    }
    onSubmit()
  }

  function handleToggleDetail () {
    setDetail(v => !v)
  }

  function handleOnlineShoppingAvailableChange (ev: ChangeEvent<HTMLInputElement>) {
    onValueChanged('onlineShoppingAvailable', String(ev.currentTarget.checked), !detail)
  }

  function handleFurusatoMoneyAvailableChange (ev: ChangeEvent<HTMLInputElement>) {
    onValueChanged('furusatoMoneyAvailable', String(ev.currentTarget.checked), !detail)
  }

  function handleCardUsableChange (ev: ChangeEvent<HTMLInputElement>) {
    onValueChanged('cardUsable', String(ev.currentTarget.checked), !detail)
  }

  function handleWindowResized () {
    const initialHeight = windowHeightRef.current
    // NOTE: 元の高さに戻ったらソフトウェアキーボードが隠れたとみなす
    // SEE: https://stackoverflow.com/questions/49006284/how-to-blur-a-input-area-when-virtual-keyboard-is-hidden-using-back-button
    if (window.innerHeight === initialHeight) {
      inputRef.current!.blur()
    }
  }

  useEffect(() => {
    windowHeightRef.current = window.innerHeight
    window.addEventListener('resize', handleWindowResized)
    return () => {
      window.removeEventListener('resize', handleWindowResized)
    }
  }, [])

  function renderOnlineShoppingAvailableInput () {
    return (
      <label className="inline-block pr-2">
        <input
          type="checkbox"
          onChange={handleOnlineShoppingAvailableChange}
          checked={values.onlineShoppingAvailable === 'true'}
        />
        <span className="ml-2">通販・EC可のみ</span>
      </label>
    )
  }

  function renderFurusatoMoneyAvailableInput () {
    return (
      <label className="inline-block pr-2">
        <input
          type="checkbox"
          onChange={handleFurusatoMoneyAvailableChange}
          checked={values.furusatoMoneyAvailable === 'true'}
        />
        <span className="ml-2">ë旅マネー利用可</span>
      </label>
    )
  }

  function renderCardUsableInput () {
    return (
      <label className="inline-block pr-2">
        <input
          type="checkbox"
          onChange={handleCardUsableChange}
          checked={values.cardUsable === 'true'}
        />
        <span className="ml-2">カード機能利用可</span>
      </label>
    )
  }

  return (
    <form ref={formRef} onSubmit={handleSubmit} className="flex flex-col">
      <div className="flex">
        <div className="px-3 py-2 rounded bg-gray-100 flex grow items-center">
          <i className="far fa-search text-gray-500" />
          <input ref={inputRef} type="search" name="keyword" id="store_search_form_keyword"
                 value={values.keyword || ''} onChange={ev => onValueChanged('keyword', ev.currentTarget.value)}
                 placeholder="お店を探す （例: コーヒー 東京）"
                 className="border-0 bg-gray-100 p-0 ml-2 w-full" />
        </div>
        <div className="ml-2">
          <button type="button" onClick={handleToggleDetail} className="flex flex-col items-center whitespace-nowrap">
            <i className={`far text-primary text-lg ${detail ? 'fa-times' : 'fa-sliders-h'}`} />
            <span className="text-xxs">絞り込み</span>
          </button>
        </div>
      </div>
      <div className="mt-2">
        { renderFurusatoMoneyAvailableInput() }
        { renderCardUsableInput() }
        { supportOnlineShoppingAvailable ? renderOnlineShoppingAvailableInput() : null }
      </div>
      { detail ? (<div className="mt-3">{renderDetail()}</div>) : null }
    </form>
  )
}

type DetailSearchContainerProps = {
  children: ReactNode
}

function DetailSearchContainer ({ children }: DetailSearchContainerProps) {
  return (
    <>
      <div className="store-search-detail-container">
        <div className="text-center font-bold text-lg">
          ジャンルで探す
        </div>
        {children}
      </div>
      <div className="mx-5 mt-5">
        <button className="btn btn-block btn-rounded btn-purple">
          検索
        </button>
      </div>
    </>
  )
}

type CategoryLabelProps = {
  selected: string | null;
  onChange: (value: string | null) => void;
} & StoreCategoryProps

function CategoryLabel ({ id, name, icon, selected, onChange }: CategoryLabelProps) {
  const inputId = `store_search_categories_${id}`
  const inputValue = id === null ? 'other' : String(id)
  const checked = inputValue === selected

  return (
    <DetailSearchLabel
      id={inputId}
      name="category_id"
      value={inputValue}
      checked={checked}
      onChange={onChange}
      label={name}
      image={icon}
    />
  )
}

type CommunityLabelProps = {
  selected: string | null;
  onChange: (value: string | null) => void;
} & CommunityProps

function CommunityLabel ({ id, name, logo, selected, onChange }: CommunityLabelProps) {
  const inputId = `store_search_communities_${id}`
  const checked = selected === String(id)

  return (
    <DetailSearchLabel
      id={inputId}
      name="community_id"
      value={String(id)}
      checked={checked}
      onChange={onChange}
      label={name}
      image={logo}
    />
  )
}

type DetailSearchLabelProps = {
  id: string;
  name: string;
  value: string;
  checked: boolean;
  onChange: (value: string | null) => void;
  label: string;
  image: string;
}

function DetailSearchLabel ({ id, name, value, checked, onChange, label, image }: DetailSearchLabelProps) {
  function handleChange (ev: ChangeEvent<HTMLInputElement>) {
    onChange(ev.currentTarget.value)
  }

  function handleClick (ev: MouseEvent<HTMLInputElement>) {
    // NOTE: 選択済みの radio を再度タップしたら選択クリア
    if (ev.currentTarget.checked) {
      onChange(null)
    }
  }

  return (
    <div>
      <input
        type="radio"
        className="hidden"
        value={value}
        id={id}
        checked={checked}
        name={name}
        onChange={handleChange}
        onClick={handleClick}
      />
      <label htmlFor={id} className="store-search-checkbox-label">
        <div className="mr-1">
          <span className="inline-block w-8 h-8 p-1 rounded-md flex items-center justify-center shrink bg-white">
            <img src={image} />
          </span>
        </div>
        <span>{label}</span>
      </label>
    </div>
  )
}
