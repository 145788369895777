import { onConnect } from '@sonicgarden/onconnect'

onConnect('.js-credit-topup-calculator-input', (el) => {
  const input = el as HTMLInputElement

  const adminFee = Number(input.dataset.adminFee)
  input.addEventListener('change', () => recalculate(input, adminFee))
  recalculate(input, adminFee)
})

function recalculate (el: HTMLInputElement, adminFee: number) {
  const billingAmount = Math.ceil(Number(el.value) * (1 + adminFee * 0.01))
  const result = document.querySelector('.js-credit-topup-calculator-result')
  if (result) {
    result.textContent = billingAmount.toLocaleString()
  }
}
