import { ChangeEvent, useState } from 'react'
import axios, { AxiosError } from 'axios'
import { client } from 'libs/ajax'
import { alert } from 'libs/flash'

let generatedId = 0

export type UploadedImage = {
  signedId: string
  url: string
}

type Uploading = { status: 'uploading' }
type Uploaded = { status: 'uploaded', data: UploadedImage }
type Errored = { status: 'error' }
export type ImageStatus = { id: number } & (Uploading | Uploaded | Errored)

export function useUploadImages (path: string, uploadedImages: UploadedImage[]) {
  const initialImages: ImageStatus[] = uploadedImages.map((img) => ({ id: ++generatedId, status: 'uploaded', data: img }))
  const [images, setImages] = useState<ImageStatus[]>(initialImages)

  function handleDelete (id: number) {
    setImages((images) => images.filter((img) => img.id !== id))
  }

  type UploadImageResult = Uploaded | Errored
  function updateImageStatus (id: number, data: UploadImageResult) {
    setImages((imgs) => imgs.map((img) => {
      if (img.id === id) {
        return { id, ...data }
      } else {
        return img
      }
    }))
  }

  async function startUpload (id: number, file: File) {
    const newImage: ImageStatus = { id, status: 'uploading' }
    setImages((images) => [...images, newImage])

    const formData = new FormData()
    formData.append('file', file)
    try {
      const { data }: { data: UploadedImage } = await client.post(path, formData)
      updateImageStatus(id, { status: 'uploaded', data })
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const err = e as AxiosError<any>
        if (err.response && err.response.data) {
          const errors: string[] = err.response.data.errors
          errors.forEach(msg => alert(msg))
        }
        updateImageStatus(id, { status: 'error' })
      } else {
        throw e
      }
    }
  }

  function handleFileChange (event: ChangeEvent<HTMLInputElement>) {
    const input = event.currentTarget
    const files = input.files
    if (files && files.length > 0) {
      for (const file of Array.from(files)) {
        const id = ++generatedId
        startUpload(id, file)
      }
    }

    input.value = '' // clear
  }

  return {
    images,
    handleDelete,
    handleFileChange
  }
}
