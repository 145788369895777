import { useState, useEffect, createRef } from 'react'

type Props = {
  text: string;
  label: string;
}

export function ExpandableText ({ label, text }: Props) {
  const [truncated, setTruncated] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const ref = createRef<HTMLDivElement>()

  useEffect(() => {
    if (!ref.current) { return }
    setTruncated(ref.current.offsetHeight < ref.current.scrollHeight)
  }, [ref])

  function handleClick () {
    setExpanded(true)
  }

  // NOTE: rewrap の children だと表示までに一瞬遅延が入ってこのコンポーネントとしては見栄えが悪いのであえて dangerouslySetInnerHTML を使っている
  return (
    <div className="relative">
      <div ref={ref} className={expanded ? '' : 'overflow-hidden max-h-12'} dangerouslySetInnerHTML={{ __html: text }} />
      <button
        type="button"
        className={`absolute top-0 left-0 h-full w-full bg-transparent ${truncated && !expanded ? '' : 'invisible'}`}
        onClick={handleClick}
      >
        <span className="absolute bottom-0 right-0 text-gray-400 bg-white">
          {label}
        </span>
      </button>
    </div>
  )
}
