import { ChangeEvent, useState } from 'react'

type Props = {
  target: string;
}

function toggleInputType (showAsPlain: boolean, targetSelector: string) {
  const el = document.querySelector<HTMLInputElement>(targetSelector)
  if (!el) { return }

  el.type = showAsPlain ? 'text' : 'password'
}

export function ShowPasswordLabel ({ target }: Props) {
  const [shown, setShown] = useState(false)

  function handleChange (event: ChangeEvent<HTMLInputElement>) {
    const { currentTarget } = event
    toggleInputType(currentTarget.checked, target)
    setShown(currentTarget.checked)
  }

  const text = shown ? '隠す' : '表示する'
  return (
    <label className="cursor-pointer">
      <input type="checkbox" onChange={handleChange} className="hidden" />
      { text }
    </label>
  )
}
