import { onConnectedElementEvent } from 'libs/event'

onConnectedElementEvent('.js-dropdown', 'click', handleClickDropdown)
onConnectedElementEvent('.js-dropdown-close-button', 'click', handleClickCloseButton)

export function init () {
  document.addEventListener('click', (ev) => {
    handleClickOutside(ev)
  })
}

export function destroy () {
  for (const dropdown of dropdownElements()) {
    closeDropdown(dropdown)
  }
}

function handleClickOutside (ev: MouseEvent) {
  const { target } = ev
  const clickedElement = target as HTMLElement
  for (const dropdown of dropdownElements()) {
    if (!dropdown.contains(clickedElement)) {
      closeDropdown(dropdown)
    }
  }
}

function handleClickDropdown (ev: MouseEvent) {
  const details = closestDetails(ev)
  if (!details || details.open) {
    return
  }

  const xpos = ev.clientX < window.innerWidth / 2 ? 'is-left' : 'is-right'
  const ypos = ev.clientY < window.innerHeight / 2 ? 'is-top' : 'is-bottom'
  details.classList.remove('is-left', 'is-right', 'is-top', 'is-bottom')
  details.classList.add(xpos, ypos)
}

function handleClickCloseButton (ev: MouseEvent) {
  const details = closestDetails(ev)
  if (details) {
    closeDropdown(details)
  }
}

function closestDetails (ev: Event): HTMLDetailsElement | null {
  const { target } = ev
  const el = target as HTMLElement
  return el.closest<HTMLDetailsElement>('.js-dropdown')
}

function dropdownElements () {
  return document.querySelectorAll<HTMLElement>('.js-dropdown')
}

function closeDropdown (el: HTMLElement) {
  el.removeAttribute('open')
}
