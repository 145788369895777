export function findElementByDataAttr<T extends HTMLElement = HTMLElement> (el: HTMLElement, name: string): T | null {
  const selector = el.dataset[name]
  if (!selector) { return null }

  return document.querySelector<T>(selector)
}

export function updateAnchorQueryParams (el: HTMLAnchorElement, queryParams: URLSearchParams) {
  const url = new URL(el.href)
  url.search = queryParams.toString()
  el.href = url.toString()
}
