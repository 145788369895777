type GetTokenRequest = {
  cardno: string
  expire: string
  securitycode: string
  holdername: string
}

type FunctionName = string

type MultipaymentInterface = {
  init(shopId: string): void
  getToken(request: GetTokenRequest, callback: FunctionName): void
}

type GetTokenCallback = (response: any) => void

declare global {
  interface Window { // eslint-disable-line no-unused-vars
    Multipayment: MultipaymentInterface;
    execRegisterCreditCard: GetTokenCallback
  }
}

export function loadMultipayment (): MultipaymentInterface {
  return window.Multipayment
}

const ERROR_MESSAGES: { [key: string]: string; } = {
  100: 'カード番号を入力してください',
  101: 'カード番号の入力に誤りがあります(数字以外を含む)',
  102: 'カード番号の入力に誤りがあります(10-16桁の範囲外)',
  110: '有効期限を入力してください',
  111: '有効期限の入力に誤りがあります(数字以外を含む)',
  112: '有効期限の入力に誤りがあります(6又は4桁以外)',
  113: '有効期限の入力に誤りがあります(月が13以上)',
  121: 'セキュリティコードの入力に誤りがあります(数字以外を含む)',
  122: 'セキュリティコードの入力に誤りがあります(桁数)',
  131: 'ご契約者氏名の入力に誤りがあります(半角英数字、一部の記号以外を含む)',
  132: 'ご契約者氏名の入力に誤りがあります(51桁以上)',
  141: '発行数フォーマットエラー(数字以外を含む)',
  142: '発行数フォーマットエラー(1-10の範囲外)',
  150: 'カード情報を暗号化した情報必須チェックエラー',
  160: 'ショップID必須チェックエラー',
  161: 'ショップIDフォーマットエラー(14 桁以上)',
  162: 'ショップIDフォーマットエラー(半角英数字以外)',
  170: '公開鍵ハッシュ値必須チェックエラー',
  180: 'ショップID または公開鍵ハッシュ値がマスターに存在しない',
  190: 'カード情報(Encrypted)が復号できない',
  191: 'カード情報(Encrypted)復号化後フォーマットエラー',
  200: 'callback 必須チェックエラー',
  201: 'Callback フォーマットエラー(半角英数字、アンダースコア、ピリオド以外を含む)',
  501: 'トークン用パラメータ(id)が送信されていない',
  502: 'トークン用パラメータ(id)がマスターに存在しない',
  511: 'トークン用パラメータ(cardInfo)が送信されていない',
  512: 'トークン用パラメータ(cardInfo)が復号できない',
  521: 'トークン用パラメータ(key)が送信されていない',
  522: 'トークン用パラメータ(key)が復号できない',
  531: 'トークン用パラメータ(callBack)が送信されていない',
  541: 'トークン用パラメータ(hash)が存在しない',
  551: 'トークン用apikeyが存在しないID',
  552: 'トークン用apikeyが有効ではない',
  901: 'マルチペイメント内部のシステムエラー',
  902: '処理が混み合っています。しばらく時間をおいてから再度お試しください'
}

export function codeToErrorMessage (resultCode: string): string {
  return ERROR_MESSAGES[resultCode] || '予期せぬエラーが発生しました。時間を置いてお試しください。'
}
