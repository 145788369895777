type Value = string | number | boolean
type InputProvider = (name: string, value: Value | null | undefined) => void
type FormBuilder = (input: InputProvider) => void

export function requestSubmit (url: string, method: string, builder: FormBuilder) {
  const form = document.createElement('form')
  form.action = url
  form.method = method

  builder((name, value) => {
    if (!value) { return }

    const input = document.createElement('input')
    input.name = name
    input.value = String(value)
    form.appendChild(input)
  })

  document.body.appendChild(form)
  form.submit()
}
