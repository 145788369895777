import { onConnect } from '@sonicgarden/onconnect'
import { Particle } from 'libs/particle'

onConnect('.js-sign-in-background', (el) => {
  const particle = new Particle(el)
  particle.start()

  return () => {
    particle.stop()
  }
})
