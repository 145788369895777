const density = 16 // パーティクルの数
const colors = [
  'rgba(133, 14, 132, .6)',
  'rgba(65, 183, 153, .6)',
  'rgba(255, 222, 55, .9)',
  'rgba(0, 136, 206, .6)',
  'rgba(246, 173, 60, .6)'
]
const baseSize = 2 // 大きさ
const baseSpeed = 13 // スピード

class Dot {
  ctx: CanvasRenderingContext2D
  size: number
  color: string
  pos: { x: number, y: number }
  vec: { x: number, y: number }

  constructor (ctx: CanvasRenderingContext2D) {
    this.ctx = ctx
    this.size = Math.floor(Math.random() * 3) + baseSize
    this.color = colors[~~(Math.random() * 5)]
    this.pos = { // 位置
      x: Math.random() * ctx.canvas.width,
      y: Math.random() * ctx.canvas.height
    }

    const rot = Math.random() * 360 // ランダムな角度
    const angle = rot * Math.PI / 180
    const speed = this.size / baseSpeed // 大きさによって速度変更
    this.vec = {
      x: Math.cos(angle) * speed,
      y: Math.sin(angle) * speed
    }
  }

  update () {
    this.draw()

    this.pos.x += this.vec.x
    this.pos.y += this.vec.y

    // 画面外に出たら反対へ再配置
    const canvasWidth = this.ctx.canvas.width
    const canvasHeight = this.ctx.canvas.height
    if (this.pos.x > canvasWidth + 10) {
      this.pos.x = -5
    } else if (this.pos.x < 0 - 10) {
      this.pos.x = canvasWidth + 5
    } else if (this.pos.y > canvasHeight + 10) {
      this.pos.y = -5
    } else if (this.pos.y < 0 - 10) {
      this.pos.y = canvasHeight + 5
    }
  }

  draw () {
    this.ctx.fillStyle = this.color
    this.ctx.beginPath()
    this.ctx.arc(this.pos.x, this.pos.y, this.size, 0, 2 * Math.PI, false)
    this.ctx.fill()
  }
}

export class Particle {
  ctx: CanvasRenderingContext2D
  size: { width: number; height: number }
  dots: Dot[]
  timer?: number

  constructor (wrapper: HTMLElement) {
    this.size = {
      width: wrapper.offsetWidth,
      height: wrapper.offsetHeight
    }
    const canvas = wrapper.querySelector<HTMLCanvasElement>('canvas')!
    this.ctx = canvas.getContext('2d')!
    this.dots = []
  }

  start () {
    this.cancelAnimation()

    const { canvas } = this.ctx
    canvas.width = this.size.width
    canvas.height = this.size.height

    this.dots = []
    for (let i = 0; i < density; ++i) {
      this.dots.push(new Dot(this.ctx))
    }

    this.update()
  }

  stop () {
    this.cancelAnimation()
  }

  cancelAnimation () {
    if (this.timer) {
      cancelAnimationFrame(this.timer)
    }
    this.timer = undefined
  }

  update = () => {
    this.timer = requestAnimationFrame(this.update)

    this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height)
    for (const dot of this.dots) {
      dot.update()
    }
  }
}
