import { onConnectedElementEvent } from 'libs/event'

onConnectedElementEvent('.js-read-more', 'click', handleClick)

function handleClick (ev: MouseEvent) {
  const { currentTarget } = ev
  if (!currentTarget) { return }

  ev.preventDefault()

  const el = currentTarget as HTMLElement
  readMore(el)
}

function readMore (link: HTMLElement) {
  for (const el of document.querySelectorAll<HTMLElement>(link.dataset.readMoreTarget!)) {
    el.classList.remove('hidden')
  }
  link.classList.add('hidden')
}
