type MessageHandler = {
  postMessage(msg: unknown): void;
}

export type IOSJsInterface = {
  scanQrCode: MessageHandler;
  scanQrCodeCardTransfer: MessageHandler;
  playSound: MessageHandler;
  sendText: MessageHandler;
  download: MessageHandler;
  startGetCurrentLocation?: MessageHandler;
}

type WKWebView = {
  messageHandlers: IOSJsInterface;
}

declare global {
  interface Window { // eslint-disable-line no-unused-vars
    webkit: WKWebView;
  }
}

export class IOSBridge {
  scanQrCode () {
    this.ios.scanQrCode.postMessage(null)
  }

  scanQrCodeCardTransfer (): boolean {
    return this.post('scanQrCodeCardTransfer', null)
  }

  playSound () {
    this.ios.playSound.postMessage(null)
  }

  sendText (text: string) {
    this.ios.sendText.postMessage({ text })
  }

  download (url: string, filename: string) {
    this.ios.download.postMessage({ url, filename })
  }

  startGetCurrentLocation (): boolean {
    return this.post('startGetCurrentLocation', null)
  }

  post (name: keyof IOSJsInterface, msg: unknown): boolean {
    const handler = this.ios[name]
    if (handler) {
      handler.postMessage(msg)
      return true
    } else {
      // 古いアプリでios側にハンドラーが存在してなければfalseを返す
      return false
    }
  }

  get ios (): IOSJsInterface {
    return window.webkit.messageHandlers
  }
}
