import { Howl } from 'howler'

type SoundParams = {
  path: string;
}

export function playSound (file: string) {
  new Howl({ src: file }).play()
}

export function extractSoundParameters (el: HTMLElement | null): SoundParams | null {
  if (!el) { return null }

  const path = el.dataset.path
  if (!path) { return null }

  return { path }
}
