import type { MobileBridgeImpl } from '../mobile_bridge'
import { handleGetCurrentLocation, LatLng } from 'libs/location'

export class EumoBridge {
  latitude: number
  longitude: number
  native: MobileBridgeImpl

  constructor (native: MobileBridgeImpl) {
    this.native = native
    // NOTE: 初期座標: Tokyo
    this.latitude = 35.685175
    this.longitude = 139.7528
  }

  setLocation (location: LatLng | null, error?: string) {
    handleGetCurrentLocation(location, error)
  }
}
