import * as Rails from '@rails/ujs'
import { Turbo } from '@hotwired/turbo-rails'
import 'channels'

import 'initializers/shared'
import 'initializers/apps'

import 'stylesheets/libs'
import 'stylesheets/apps'

// app/javascript/images 以下のすべての画像を import する
require.context('images', true, /\.(png|jpg|jpeg|ico|svg|gif)$/)

Turbo.setFormMode('optin')

Rails.start()
