import { onConnectedElementEvent } from 'libs/event'

onConnectedElementEvent('.js-confirmation-checkbox', 'change', handleChange)

function handleChange (ev: Event) {
  const checkbox = ev.currentTarget as HTMLInputElement
  const targetId = checkbox.dataset.confirmationTarget
  if (!targetId) { return }
  const targetEl = document.getElementById(targetId)
  if (!targetEl) { return }

  if (checkbox.checked) {
    targetEl.removeAttribute('disabled')
  } else {
    targetEl.setAttribute('disabled', '')
  }
}
