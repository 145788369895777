import { Popover } from '@headlessui/react'

type Community = {
  logo?: string
  name: string
  path: string
  selected: boolean
}

type Props = {
  label: string
  communities: Community[]
}

export function CommunitySelect ({ label, communities }: Props) {
  return (
    <Popover className="relative">
      <Popover.Button className="text-sm text-primary">
        <span>{ label }</span>
        <i className="ml-1 far fa-chevron-down" />
      </Popover.Button>

      <Popover.Panel className="absolute z-10 right-0 w-max">
        <div className="mt-2 p-5 bg-white border rounded shadow space-y-2">
          { communities.map((community) => <CommunityOption key={community.path} {...community} />) }
        </div>
      </Popover.Panel>
    </Popover>
  )
}

function CommunityOption ({ logo, name, path, selected }: Community) {
  return (
    <a className={`${selected ? 'bg-gray-100' : 'bg-white'} flex items-center rounded-full border border-gray-300 py-2 px-4 text-sm text-default`} href={path}>
      { logo ? <img src={logo} className="w-8 h-8 mr-1" /> : null}
      <span>{name}</span>
    </a>
  )
}
