import { rewrap } from '@sonicgarden/rewrap'
import {
  Menu,
  CreditCard,
  CommunitySelect,
  StoreMap,
  StoreListSearchForm
} from 'components/apps'

rewrap('x-menu', Menu)
rewrap('x-credit-card', CreditCard)
rewrap('x-community-select', CommunitySelect)
rewrap('x-store-map', StoreMap)
rewrap('x-store-list-search-form', StoreListSearchForm)
