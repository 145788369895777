import { Loader } from '@googlemaps/js-api-loader'
import { MarkerClusterer } from '@googlemaps/markerclusterer'
import type { Cluster, ClusterStats } from '@googlemaps/markerclusterer'
import { client as ajax } from 'libs/ajax'
import { Locator, LatLng } from 'libs/location'
import { alert } from 'libs/flash'

let _google: typeof google | undefined

type MarkerOption = Partial<{
  url: string;
  icon: google.maps.MarkerOptions['icon'];
}>

export class Marker {
  id: string
  marker: google.maps.Marker
  url: string | undefined
  map: Map | null = null // eslint-disable-line no-use-before-define

  constructor (id: unknown, lat: number, lng: number, title: string, option: MarkerOption) {
    this.id = String(id)
    this.url = option?.url
    this.marker = new _google!.maps.Marker({
      position: { lat, lng },
      title,
      icon: option?.icon
    })

    if (this.url) {
      this.marker.addListener('click', this.handleClick)
    }
  }

  get isAdded () {
    return this.map != null
  }

  remove () {
    this.marker.setMap(null)
    this.map = null
  }

  addTo (map: Map) {
    this.marker.setMap(map.map)
    this.map = map
  }

  moveTo (lat: number, lng: number) {
    this.marker.setPosition({ lat, lng })
  }

  isInBounds (bounds: google.maps.LatLngBounds): boolean {
    const pos = this.marker.getPosition()
    return !!pos && bounds.contains(pos)
  }

  distanceFrom (lat: number, lng: number): number | null {
    const pos = this.marker.getPosition()
    if (!pos) { return null }

    // NOTE: 三角形の斜辺を使う簡易的な実装。現在の要件にはこれで十分
    const x = Math.abs(pos.lng() - lng)
    const y = Math.abs(pos.lat() - lat)
    return Math.sqrt(x * x + y * y)
  }

  handleClick = async () => {
    if (this.map) {
      this.map.handleClickMarker(this)
    }
  }

  static newCurrentLocation (lat: number, lng: number) {
    const svg = window.btoa(`
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
  <circle fill="#000000" cx="6" cy="6" opacity=".3" r="6" />
  <circle fill="#ffffff" cx="6" cy="6" opacity="1" r="5" />
  <circle fill="#4285f4" cx="6" cy="6" opacity="1" r="4" />
</svg>`)

    return new Marker('current-location', lat, lng, '現在地', {
      icon: {
        url: `data:image/svg+xml;base64,${svg}`,
        scaledSize: this.iconSize(22, 22)
      }
    })
  }

  static iconSize (width: number, height: number): google.maps.Size {
    return new _google!.maps.Size(width, height)
  }
}

class ClusterRenderer {
  render (cluster: Cluster, _stats: ClusterStats): google.maps.Marker {
    const { position, count } = cluster
    return new _google!.maps.Marker({
      position,
      icon: {
        url: '/cluster_marker.svg',
        scaledSize: Marker.iconSize(30, 40.5)
      },
      label: {
        className: 'map-cluster-label',
        text: String(count),
        color: '#8634A3',
        fontSize: '1.25rem',
        fontWeight: 'bold'
      },
      zIndex: 1000 + count
    })
  }
}

type BoundsChangedHandler = (bounds: google.maps.LatLngBoundsLiteral) => void
function defaultGoogleMapOptions (): google.maps.MapOptions {
  return {
    zoomControlOptions: {
      position: google.maps.ControlPosition.RIGHT_TOP
    },
    clickableIcons: false,
    styles: [
      {
        featureType: 'poi',
        stylers: [
          { visibility: 'off' }
        ]
      },
      {
        featureType: 'landscape.man_made',
        stylers: [
          { visibility: 'off' }
        ]
      }
    ]
  }
}

// NOTE: 外側の要素に padding-left: 12px がついてるので、margin-right をつけて均一に配置されるよう調整している
const INFO_WINDOW_SPINNER = '<div style="margin-right: 12px" class="w-10 h-10 flex justify-center items-center"><i class="far fa-spinner-third fa-spin" /></div>'

class Map {
  map: google.maps.Map
  markers: Marker[] = []
  clusterer: MarkerClusterer
  locator: Locator | null = null
  boundsChangedHandler: BoundsChangedHandler | null = null
  currentLocationMarker: Marker | null = null
  infoWindow: google.maps.InfoWindow | null = null

  constructor (el: HTMLElement, options: google.maps.MapOptions) {
    this.map = new _google!.maps.Map(el, { ...options, ...defaultGoogleMapOptions() })
    this.clusterer = new MarkerClusterer({ map: this.map, renderer: new ClusterRenderer() })
    this.map.addListener('bounds_changed', this.handleBoundsChanged)
  }

  get bounds () {
    const bounds = this.map.getBounds() || null
    if (!bounds) { return null }

    const ne = bounds.getNorthEast()
    const sw = bounds.getSouthWest()
    return {
      east: ne.lng(),
      north: ne.lat(),
      west: sw.lng(),
      south: sw.lat()
    }
  }

  onBoundsChanged (handler: BoundsChangedHandler) {
    this.boundsChangedHandler = handler
  }

  handleBoundsChanged = () => {
    const bounds = this.bounds
    if (bounds && this.boundsChangedHandler) {
      this.boundsChangedHandler(bounds)
    }
  }

  dispose () {
    if (this.locator) {
      this.locator.dispose()
      this.locator = null
    }
  }

  setMarkers (markers: Marker[]) {
    for (const m of this.markers) {
      this.unmarkMarker(m)
    }
    this.markers = markers
  }

  markMarker (marker: Marker) {
    if (marker.isAdded) { return }

    marker.addTo(this)
    this.clusterer.addMarker(marker.marker)
  }

  unmarkMarker (marker: Marker) {
    if (!marker.isAdded) { return }

    this.clusterer.removeMarker(marker.marker)
    marker.remove()
  }

  renderMarkers () {
    const bounds = this.map.getBounds()
    for (const m of this.markers) {
      if (bounds && m.isInBounds(bounds)) {
        this.markMarker(m)
      } else {
        this.unmarkMarker(m)
      }
    }
  }

  pinMarker ({ id }: { id: string }) {
    const marker = this.markers.find(marker => marker.id === id)
    if (marker) {
      this.map.panTo(marker.marker.getPosition()!)
      this.map.setZoom(14)
      this.openInfoWindow(marker)
    }
  }

  panToNearestMarker () {
    const center = this.map.getCenter()
    if (!center) { return }

    const marker = this.nearestMarkerFrom(center)
    if (marker) {
      this.map.panTo(marker.marker.getPosition()!)
    }
  }

  nearestMarkerFrom (center: google.maps.LatLng): Marker | null {
    let nearestMarker = null
    let minDistance = Number.MAX_VALUE
    for (const m of Object.values(this.markers)) {
      const d = m.distanceFrom(center.lat(), center.lng())
      if (d && d < minDistance) {
        minDistance = d
        nearestMarker = m
      }
    }
    return nearestMarker
  }

  moveToCurrentLocation (): boolean {
    if (this.locator) {
      this.locator.dispose()
    }

    const locator = new Locator()
    const started = locator.start((location) => {
      if (location) {
        this.setCurrentLocation(location)
      }
      this.classList.remove('map-current-location-busy')
    })

    if (started) {
      this.locator = locator
      this.classList.add('map-current-location-busy')
      return true
    } else {
      return false
    }
  }

  setCurrentLocation (location: LatLng) {
    const { latitude, longitude } = location
    this.map.setCenter({ lat: latitude, lng: longitude })
    this.map.setZoom(17)

    if (!this.currentLocationMarker) {
      this.currentLocationMarker = Marker.newCurrentLocation(latitude, longitude)
      this.currentLocationMarker.addTo(this)
    } else {
      this.currentLocationMarker.moveTo(latitude, longitude)
    }
  }

  async handleClickMarker (marker: Marker) {
    await this.openInfoWindow(marker)
  }

  async openInfoWindow (marker: Marker) {
    if (!marker.url) { return }
    if (!this.infoWindow) {
      this.infoWindow = new _google!.maps.InfoWindow({
        content: INFO_WINDOW_SPINNER
      })
    } else {
      this.infoWindow.setContent(INFO_WINDOW_SPINNER)
    }

    this.infoWindow.open({
      anchor: marker.marker,
      map: this.map,
      shouldFocus: false
    })

    const { data } = await ajax.get(marker.url)
    this.infoWindow.setContent(data)
  }

  get classList (): DOMTokenList {
    return this.map.getDiv().classList
  }
}

// NOTE: https://developers.google.com/maps/documentation/javascript/examples/control-custom
function addMoveToCurrentLocationControl (map: Map) {
  const icon = document.createElement('i')
  icon.classList.add('far', 'fa-location', 'map-current-location-icon')
  const spinner = document.createElement('i')
  spinner.classList.add('far', 'fa-spinner-third', 'fa-spin', 'map-current-location-spinner')

  const button = document.createElement('button')
  button.type = 'button'
  button.style.width = '40px'
  button.style.height = '40px'
  button.style.boxShadow = 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px'
  button.style.borderRadius = '2px'
  button.style.backgroundColor = 'white'
  button.style.color = '#8634A3'
  button.style.fontSize = '1.5rem'
  button.appendChild(icon)
  button.appendChild(spinner)

  // TODO: 二重クリック防止
  button.addEventListener('click', () => {
    if (!map.moveToCurrentLocation()) {
      alert('アプリを最新化してください。お使いのバージョンは現在地取得をサポートしていません。')
    }
  })

  const control = document.createElement('div')
  control.style.paddingRight = '10px'
  control.appendChild(button)

  map.map.controls[google.maps.ControlPosition.RIGHT_TOP].push(control)
}

export async function newMap (apiKey: string, el: HTMLElement, options: google.maps.MapOptions): Promise<Map> {
  await loadApi(apiKey)
  const map = new Map(el, options)
  addMoveToCurrentLocationControl(map)
  return map
}

async function loadApi (apiKey: string): Promise<void> {
  if (_google) {
    return
  }

  const loader = new Loader({
    apiKey,
    version: 'weekly',
    libraries: ['places']
  })
  _google = await loader.load()
}
