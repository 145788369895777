import { onConnect } from '@sonicgarden/onconnect'

onConnect("input[type='radio'].js-radio-switcher", (el) => {
  const input = el as HTMLInputElement
  bindEvent(input)
  if (input.checked) {
    switchRadioButton(input)
  }
})

function bindEvent (radio: HTMLInputElement) {
  radio.addEventListener('click', () => {
    switchRadioButton(radio)
  })
}

function switchRadioButton (radio: HTMLInputElement) {
  const activeTargetContent = document.querySelector<HTMLElement>(`[data-switcher-value="${radio.getAttribute('id')!}"]`)
  if (!activeTargetContent) { return }

  for (const el of document.querySelectorAll<HTMLInputElement>(`input[type="radio"].js-radio-switcher[name="${radio.getAttribute('name')!}"]`)) {
    const content = document.querySelector<HTMLElement>(`[data-switcher-value="${el.getAttribute('id')!}"]`)
    if (!content) { continue }

    if (activeTargetContent === content) {
      activateContent(content)
    } else {
      inactiveContent(content)
    }
  }
}

function activateContent (content: HTMLElement) {
  content.classList.remove('hidden')

  for (const input of content.querySelectorAll<HTMLInputElement>('input')) {
    input.disabled = false
  }
}

function inactiveContent (content: HTMLElement) {
  content.classList.add('hidden')

  for (const input of content.querySelectorAll<HTMLInputElement>('input')) {
    input.disabled = true
  }
}
