import { ChangeEvent, useState } from 'react'
import { useUploadImages, ImageStatus, UploadedImage } from 'libs/hooks/use_upload_images'
import { Spinner } from '../shared/spinner'

type Props = {
  name: string
  removeName: string
  path: string
  image: UploadedImage | null
  imageStyle: 'rounded' | 'square'
}

export function UploadSingleImage ({ name, removeName, path, image: uploadedImage, imageStyle }: Props) {
  const { images, handleDelete, handleFileChange } = useUploadImages(path, uploadedImage ? [uploadedImage] : [])
  const [removed, setRemoved] = useState(false)
  const image = images.length > 0 ? images[0] : null

  function isInitialUploadedImageSelected () {
    return image && image.status === 'uploaded' && image.data.signedId === uploadedImage?.signedId
  }

  function _handleDelete () {
    if (!image) { return }

    if (isInitialUploadedImageSelected()) {
      setRemoved(true)
    }
    handleDelete(image.id)
  }

  function _handleFileChange (event: ChangeEvent<HTMLInputElement>) {
    if (image) {
      handleDelete(image.id)
    }
    handleFileChange(event)
  }

  return (
    <>
      <input name={removeName} type="hidden" value={(removed && uploadedImage?.signedId) || ''} />
      {
        image
          ? (
            <div className="mb-3">
              <ImageView key={image.id} name={name} image={image} onDelete={_handleDelete} style={imageStyle} />
            </div>
            )
          : null
      }
      <div className="relative">
        <label htmlFor={name} className="absolute inset-0">
          <i className="far fa-paperclip mr-3" />
          <span>画像を選択する</span>
        </label>
        <input
          id={name}
          type="file"
          onChange={_handleFileChange}
          style={{ visibility: 'hidden', height: 0 }}
          accept="image/*"
        />
      </div>
    </>
  )
}

type ImageViewProps = {
  name: string
  image: ImageStatus
  onDelete: () => void
  style: Props['imageStyle']
}

function ImageView ({ name, image, onDelete, style }: ImageViewProps) {
  return (
    <div className="flex items-center justify-center relative w-24 h-24">
      <button type="button" className="absolute flex items-center justify-center -top-2 -right-2 w-5 h-5 border-2 border-gray-400 rounded-full bg-white bg-opacity-90" onClick={onDelete}>
        <i className="far fa-times" />
      </button>
      {
        (() => {
          switch (image.status) {
            case 'uploading':
              return <UploadingImageView />
            case 'uploaded':
              return <UploadedImageView name={name} style={style} {...image.data} />
            case 'error':
              return <ErroredImageView />
          }
        })()
      }
    </div>
  )
}

function UploadingImageView () {
  return (
    <span data-uploading={true}>
      <Spinner />
    </span>
  )
}

type UploadedImageProps = UploadedImage & {
  name: string
  style: Props['imageStyle']
}

function UploadedImageView ({ name, signedId, url, style }: UploadedImageProps) {
  const imageClasses = style === 'rounded' ? 'object-cover rounded-full' : 'object-contain'
  return (
    <>
      <img src={url} className={`h-full w-full ${imageClasses}`} />
      <input type="hidden" name={name} value={signedId} />
    </>
  )
}

function ErroredImageView () {
  return (
    <div className="flex flex-col items-center text-red-600">
      <i className="far fa-times" />
      <span style={{ fontSize: '0.5rem' }}>エラー</span>
    </div>
  )
}
