import { client } from './ajax'

type Address = {
  prefecture: string;
  prefectureId: number;
  city: string;
  town: string;
}

type AutoFillTargets = {
  prefecture: HTMLSelectElement;
  city: HTMLInputElement;
  address1: HTMLInputElement;
  address2: HTMLInputElement;
}

export async function zipCodeToAddresses (zipCode: string): Promise<Address[]> {
  const val = zipCode.slice(0, 10) // 郵便番号として長過ぎる文字列は事前に弾く
  const { data } = await client.get<Address[]>(`/zip_codes/${encodeURIComponent(val)}`)
  return data
}

export async function zipCodeAutoFill (zipCode: string, targets: AutoFillTargets) {
  const addrs = await zipCodeToAddresses(zipCode)
  if (addrs.length === 0) {
    targets.prefecture.value = ''
    targets.city.value = ''
    targets.address1.value = ''
    targets.address2.value = ''
  } else {
    // NOTE: 郵便番号によっては複数の住所がヒットする場合があるが、レアケースなので一旦は先頭の住所を使うようにしている
    const addr = addrs[0]
    targets.prefecture.value = String(addr.prefectureId)
    targets.city.value = addr.city
    targets.address1.value = addr.town
    targets.address2.value = ''
  }
}
