import { client } from 'libs/ajax'
import type { Bounds } from 'libs/location'

export type Store = {
  id: number;
  name: string;
  latitude: number;
  longitude: number;
  marker_icon_url: string; // eslint-disable-line camelcase
}

export type SearchParams = Partial<{
  keyword: string | null;
  bounds: Bounds | null;
  communityId: number | null;
  categoryId: number | null;
  otherCategory: boolean | null;
  realStoreExists: boolean | null;
  onlineShoppingAvailable: boolean | null;
  furusatoMoneyAvailable: boolean | null;
  cardUsable: boolean | null;
}>

type StoresResponse = Store[]

function toQueryParams (params: SearchParams): object {
  return {
    'store_search_form[keyword]': params.keyword,
    'store_search_form[east]': params.bounds && params.bounds.east,
    'store_search_form[north]': params.bounds && params.bounds.north,
    'store_search_form[west]': params.bounds && params.bounds.west,
    'store_search_form[south]': params.bounds && params.bounds.south,
    'store_search_form[community_id]': params.communityId,
    'store_search_form[category_id]': params.categoryId,
    'store_search_form[other_category]': params.otherCategory ? true : null,
    'store_search_form[real_store_exists]': params.realStoreExists ? true : null,
    'store_search_form[online_shopping_available]': params.onlineShoppingAvailable ? true : null,
    'store_search_form[furusato_money_available]': params.furusatoMoneyAvailable ? true : null,
    'store_search_form[card_usable]': params.cardUsable ? true : null
  }
}

export async function mapMarkers (params: SearchParams): Promise<StoresResponse> {
  const res = await client.request<StoresResponse>({ url: '/apps/maps/store_markers.json', params: toQueryParams(params) })
  return res.data
}

export async function mapActivities (params: SearchParams): Promise<string> {
  const res = await client.request<string>({ url: '/apps/maps/store_activities.html', params: toQueryParams(params) })
  return res.data
}
