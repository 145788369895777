import { Drawer } from 'libs/drawer'

let drawer: Drawer | null = null

export function init () {
  const overlay = document.querySelector('#js-apps-drawer-overlay')
  const menu = document.querySelector('#js-apps-drawer-menu')
  if (!overlay || !menu) { return }

  drawer = new Drawer(overlay, menu)
  bindDrawerEvents(drawer)
}

function bindDrawerEvents (drawer: Drawer) {
  const toggle = document.querySelector('#js-apps-drawer-toggle')
  toggle?.addEventListener('click', drawer.show)

  const close = drawer.menuElement.querySelector('#js-apps-drawer-close')
  close?.addEventListener('click', drawer.hide)

  const overlay = drawer.overlayElement
  overlay.addEventListener('click', drawer.hide)
}

export function destroy () {
  drawer?.hide()
  drawer = null
}
