import { onConnectedElementEvent } from 'libs/event'
import { mobileBridge } from 'libs/mobile_bridge'

onConnectedElementEvent('.js-launch-scan-qr-code', 'click', handleLaunchScanQrCode)
onConnectedElementEvent('.js-launch-scan-card-transfer-qr-code', 'click', handleLaunchScanCardTransferQrCode)
onConnectedElementEvent('.js-send-text', 'click', handleSendText)
onConnectedElementEvent('.js-download', 'click', handleDownload)

export function init () {
  mobileBridge.install()
}

function handleLaunchScanQrCode (ev: Event) {
  ev.preventDefault()
  mobileBridge.native.scanQrCode()
}

function handleLaunchScanCardTransferQrCode (ev: Event) {
  ev.preventDefault()

  if (!mobileBridge.native.scanQrCodeCardTransfer()) {
    alert('アプリを最新化してください。お使いのバージョンはカード情報の引き継ぎをサポートしていません。')
  }
}

function handleSendText (ev: MouseEvent) {
  const { currentTarget } = ev
  if (!currentTarget) return

  const el = currentTarget as HTMLElement
  const text = el.dataset.text
  if (text) {
    // NOTE: PC での動作確認をやりやすいように、非アプリ環境では alert でテキストを表示する
    try {
      mobileBridge.native.sendText(text)
    } catch {
      alert(text)
    }
  }
}

function handleDownload (ev: MouseEvent) {
  const { currentTarget } = ev
  if (!currentTarget) return

  const el = currentTarget as HTMLElement
  const { url, filename } = el.dataset
  if (url && filename) {
    mobileBridge.native.download(url, filename)
  }
}
