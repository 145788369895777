// NOTE: localStorage を有効化していないバージョンの Android アプリの場合、window.localStorage が null になる
function storage (): Storage | null {
  const s = window.localStorage as any
  if (s) {
    return s as Storage
  } else {
    return null
  }
}

export function withLocalStorage<T> (handler: (storage: Storage) => T): T | undefined {
  const s = storage()
  if (s) {
    return handler(s)
  } else {
    return undefined
  }
}
