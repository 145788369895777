import { onConnectedElementEvent } from 'libs/event'
import { showDialog } from 'libs/dialog'

onConnectedElementEvent('.js-open-dialog', 'click', handleClick)

function handleClick (event: Event) {
  const el = event.currentTarget as HTMLElement
  const targetSelector = el.dataset.dialogTarget
  if (!targetSelector) { return }

  const body = document.querySelector<HTMLTemplateElement>(targetSelector)
  if (!body) { return }

  showDialog(body)
}
