export type Balance = {
  point: number;
  money: number;
  furusatoMoney: number;
  eumoMoney: number;
  pointDiff: number;
  moneyDiff: number;
  furusatoMoneyDiff: number;
  eumoMoneyDiff: number;
  notEnough: number;
}

export class BalanceCalculator {
  moneyBalance: number
  pointBalance: number
  furusatoMoneyBalance: number
  eumoMoneyBalance: number
  useFurusatoMoney: boolean
  useEumoMoney: boolean

  constructor (
    moneyBalance: number,
    pointBalance: number,
    furusatoMoneyBalance: number,
    eumoMoneyBalance: number,
    useFurusatoMoney: boolean,
    useEumoMoney: boolean
  ) {
    this.moneyBalance = moneyBalance
    this.pointBalance = pointBalance
    this.furusatoMoneyBalance = furusatoMoneyBalance
    this.eumoMoneyBalance = eumoMoneyBalance
    this.useFurusatoMoney = useFurusatoMoney
    this.useEumoMoney = useEumoMoney
  }

  pay (amount: number): Balance {
    const r1 = this.useFurusatoMoney ? Math.max(0, amount - this.furusatoMoneyBalance) : amount
    const r2 = Math.max(0, r1 - this.pointBalance)
    const r3 = Math.max(0, r2 - this.moneyBalance)
    const furusatoMoney = this.useFurusatoMoney ? Math.max(0, this.furusatoMoneyBalance - amount) : this.furusatoMoneyBalance
    const point = Math.max(0, this.pointBalance - r1)
    const money = this.useEumoMoney ? Math.max(0, this.pointBalance - r2) : this.moneyBalance - r2
    const eumoMoney = this.eumoMoneyBalance - r3
    const notEnough = this.useEumoMoney ? -eumoMoney : -money
    return {
      point,
      money,
      furusatoMoney,
      eumoMoney,
      pointDiff: this.pointBalance - point,
      moneyDiff: money >= 0 ? this.moneyBalance - money : this.moneyBalance,
      furusatoMoneyDiff: this.furusatoMoneyBalance - furusatoMoney,
      eumoMoneyDiff: eumoMoney >= 0 ? this.eumoMoneyBalance - eumoMoney : this.eumoMoneyBalance,
      notEnough: notEnough >= 0 ? notEnough : 0
    }
  }

  topup (amount: number): Balance {
    return {
      point: this.pointBalance,
      money: this.moneyBalance + amount,
      furusatoMoney: this.furusatoMoneyBalance,
      eumoMoney: 0,
      pointDiff: 0,
      moneyDiff: amount,
      furusatoMoneyDiff: 0,
      eumoMoneyDiff: 0,
      notEnough: 0
    }
  }
}
