import { onConnectedElementEvent } from 'libs/event'
import { zipCodeAutoFill } from 'libs/zip_code'
import { findElementByDataAttr } from 'libs/dom'

onConnectedElementEvent('.js-zip-code-auto-fill', 'click', handleClick)

function handleClick (ev: Event) {
  const el = ev.currentTarget as HTMLElement
  const zipCode = findElementByDataAttr<HTMLInputElement>(el, 'zipCodeInput')
  const prefecture = findElementByDataAttr<HTMLSelectElement>(el, 'prefectureInput')
  const city = findElementByDataAttr<HTMLInputElement>(el, 'cityInput')
  const address1 = findElementByDataAttr<HTMLInputElement>(el, 'address1Input')
  const address2 = findElementByDataAttr<HTMLInputElement>(el, 'address2Input')

  if (!zipCode || !prefecture || !city || !address1 || !address2) { return }

  zipCodeAutoFill(zipCode.value, { prefecture, city, address1, address2 })
}
