export class ImageUploadForm {
  el: HTMLFormElement
  observer: MutationObserver | null = null

  constructor (el: HTMLFormElement) {
    this.el = el
  }

  start () {
    this.observer = new MutationObserver(this.handleMutationDetected)
    this.observer.observe(this.el, { childList: true, subtree: true })
  }

  stop () {
    this.observer?.disconnect()
  }

  handleMutationDetected = () => {
    if (this.el.querySelector('[data-uploading]')) {
      this.disable()
    } else {
      this.enable()
    }
  }

  disable () {
    this.submits.forEach(el => el.setAttribute('disabled', ''))
  }

  enable () {
    this.submits.forEach(el => el.removeAttribute('disabled'))
  }

  get submits (): HTMLElement[] {
    return Array.from(this.el.querySelectorAll<HTMLElement>('input[type=submit], button'))
  }
}
