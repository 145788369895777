import { onConnectedElementEvent } from 'libs/event'

onConnectedElementEvent('.js-open-print', 'click', handleOpenPrintClick)
onConnectedElementEvent('.js-print', 'click', handlePrint)

function handleOpenPrintClick (ev: Event) {
  ev.preventDefault()
  const link = ev.target as HTMLElement
  const url = link.getAttribute('data-print-url')
  const name = link.getAttribute('data-print-name')
  const printTarget = window.open(url!, name!)
  printTarget!.print()
}

function handlePrint (ev: Event) {
  ev.preventDefault()
  window.print()
}
