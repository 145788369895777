import { useState } from 'react'
import { Dialog } from './dialog'

type MenuItemProps = {
  label: string
  path: string
  method?: string
  confirm?: string
}

type Props = {
  items: MenuItemProps[]
  content: string
}

export function Menu ({ items, content }: Props) {
  const [isOpen, setIsOpen] = useState(false)

  function handleOpen () {
    setIsOpen(true)
  }

  function handleClose () {
    setIsOpen(false)
  }

  return (
    <>
      <button type="button" onClick={handleOpen} className="text-gray-600 text-xs px-2">
        { content ? <span dangerouslySetInnerHTML={{ __html: content }}></span> : <i className="fas fa-ellipsis-h"></i> }
      </button>
      <Dialog open={isOpen} onClose={handleClose} dialogPosition="end">
        <ul className="divide-y divide-gray-200 text-sm">
          { items.map((item) => <MenuItem key={item.label} {...item} />) }
        </ul>
      </Dialog>
    </>
  )
}

function MenuItem ({ label, path, method, confirm }: MenuItemProps) {
  return (
    <li className="px-3 py-3 text-center text-primary">
      <a href={path} data-method={method} data-confirm={confirm}>
        {label}
      </a>
    </li>
  )
}
