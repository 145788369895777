import * as PullToRefresh from 'pulltorefreshjs'
import { Turbo } from '@hotwired/turbo-rails'

export function init () {
  if (!document.querySelector('.js-pull-to-refresh')) { return }

  PullToRefresh.init({
    mainElement: '.js-pull-to-refresh',
    triggerElement: '.js-pull-to-refresh',
    onRefresh () {
      Turbo.visit(window.location.href, { action: 'replace' })
    }
  })
}

export function destroy () {
  PullToRefresh.destroyAll()
}
