import { onConnectedElementEvent } from 'libs/event'

onConnectedElementEvent('.js-price-input', 'input', handleInput)

function handleInput (ev: Event) {
  const { currentTarget } = ev
  if (!currentTarget) { return }

  const el = currentTarget as HTMLInputElement
  adjustPrice(el)
}

function adjustPrice (target: HTMLInputElement) {
  const currVal = target.value
  if (!currVal) { return }

  // マイナス値、小数値が入力できないように
  let fixedValue = currVal.replace(/\D/g, '').replace(/^0+/, '')
  if (fixedValue === '') {
    fixedValue = '0'
  }

  // maxで指定した桁数を越えている場合に補正する
  const max = target.getAttribute('max')
  if (max && Number.isFinite(Number(max)) && currVal.length > max.length) {
    fixedValue = fixedValue.substring(0, max.length)
  }

  if (target.value !== fixedValue) {
    target.value = fixedValue
  }
}
